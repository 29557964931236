import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';

import Heading from '@ds/components/shared/Heading';
import Text from '@ds/components/shared/Text';

import styles from './styles.module.scss';

export interface CardInfoProps {
    title: string;
    description: string;
}

const CardInfo = ({ title, description }: CardInfoProps) => {
    return (
        <div
            data-theme="light"
            className={cls(
                styles.card,
                'bg-base-400',
                'd-flex',
                'flex-column',
                'py-xxl',
                'px-lg',
            )}>
            <Heading className={cls('mb-xxl')} font="h200" component="h4">
                {title}
            </Heading>
            <Text component="div" font="b200" className={styles.description}>
                {description}
            </Text>
        </div>
    );
};

export default CardInfo;
