import React, { useState, useMemo, useRef, useCallback } from 'react';

import {
    Section,
    Container,
    cls,
    Slider,
    SliderProps,
    SliderControlProps,
    Icon,
} from '@solublestudio/soluto-design-system';

import CardYear, { CardYearProps } from '@ds/components/shared/Cards/Year';
import SliderControls from '@ds/components/shared/Slider/Controls';
import SectionsTitle from '@ds/components/shared/SectionsTitle';

import styles from './styles.module.scss';

export interface TimelineSectionProps extends SliderProps {
    title?: string;
    items: CardYearProps[];
}

const TimelineSection = ({ title, items }: TimelineSectionProps) => {
    const settings = {
        items: 1,
        controls: false,
        nav: false,
        loop: false,
        center: true,
        gutter: 20,
        fixedWidth: 246,
        responsive: {
            992: {
                gutter: 40,
                fixedWidth: 306,
            },
        },
    };

    const refSlider = useRef<Slider>();

    const [slideActive, setSlideActive] = useState(0);

    const [reachedLimit, setReachedLimit] = useState(false);

    const onSlideChange = (info: { index: number }) => {
        setSlideActive(info.index);

        setReachedLimit(info.index >= items?.length - 1);
    };

    const years = useMemo(() => {
        const yearsArray = items.map((item) => parseInt(item.title));
        let min = Math.min.apply(Math, yearsArray);
        let max = Math.max.apply(Math, yearsArray);

        let allYears = [];

        min = (Math.floor(min / 100) - 1) * 100;
        max = (Math.floor(max / 100) + 1) * 100;

        for (let i = min; i <= max; i++) {
            allYears.push(i);
        }

        return allYears;
    }, [items]);

    const percentage = useMemo(() => {
        if (!items[slideActive]) {
            return;
        }

        const actualYear = parseInt(items[slideActive].title);
        const firstYear = years[0];
        return ((actualYear - firstYear) / years.length) * 100;
    }, [slideActive, items, years]);

    const itemMin = items?.length
        ? items.length < 4
            ? [...items, ...Array(4 - items.length).fill(null)]
            : items
        : [];

    const onClickItem = useCallback((index: number) => {
        if (!refSlider?.current) {
            return;
        }
        return refSlider?.current?.goTo(index);
    }, []);

    return (
        <Section className={cls('pb-mega', styles.section)}>
            {title && (
                <Container>
                    <SectionsTitle
                        component="h2"
                        title={title}
                        className="mb-xxxl"
                    />
                </Container>
            )}
            <div
                data-sal="slide-left"
                data-sal-delay="400"
                data-sal-duration="300"
                className={styles.timelineYears}>
                <div className={cls('d-flex justify-content-center mb-xs')}>
                    <Icon name={'cuervaAsterisco'} color="#FFDA00" size={16} />
                </div>
                <div
                    className={cls('d-inline-flex', styles.years)}
                    style={{ transform: `translateX(-${percentage}%)` }}>
                    {years.map((year, index) => (
                        <div
                            key={`year-${year}`}
                            onClick={() => setSlideActive(index)}
                            data-title={index % 10 === 0 ? year : ''}
                            className={cls(
                                styles.year,
                                index % 10 === 0 && styles.tenYear,
                                'b100',
                            )}
                        />
                    ))}
                </div>
            </div>

            <div
                data-sal="slide-left"
                data-sal-delay="400"
                data-sal-duration="300"
                className={cls('d-flex flex-column-reverse')}>
                <Slider
                    onIndexChanged={onSlideChange}
                    settings={settings}
                    ref={refSlider}
                    Controls={({
                        onClickPrev,
                        onClickNext,
                        ...props
                    }: SliderControlProps) => {
                        return (
                            <SliderControls
                                className="mb-md mt-xs"
                                size="small"
                                light
                                isFirst={slideActive === 0}
                                isLast={reachedLimit}
                                onClickPrev={onClickPrev}
                                onClickNext={onClickNext}
                            />
                        );
                    }}>
                    {itemMin.map((item, i) => {
                        return (
                            <div
                                key={`timeline-item-${i}`}
                                role={
                                    i === slideActive
                                        ? 'presentation'
                                        : 'button'
                                }
                                onClick={() => onClickItem(i)}>
                                {item && (
                                    <CardYear
                                        {...item}
                                        active={i === slideActive}
                                    />
                                )}
                            </div>
                        );
                    })}
                </Slider>
            </div>
        </Section>
    );
};

export default TimelineSection;
