import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';

import Heading from '@ds/components/shared/Heading';
import Text from '@ds/components/shared/Text';

import styles from './styles.module.scss';

export interface CardChartProps {
    pretitle: string;
    title: string;
    percentage: string;
}

const CardChart = ({ title, pretitle, percentage }: CardChartProps) => {
    return (
        <div
            className={cls(
                styles.card,
                'bg-base-100',
                'text-base-1000',
                'd-flex',
                'flex-column',
                'justify-content-between',
                'p-lg',
            )}>
            <div>
                <Text
                    component="p"
                    font="b200"
                    className={cls(styles.pretitle, 'text-base-200 mb-xxs')}>
                    {pretitle}
                </Text>
                <Heading
                    className={cls(styles.title)}
                    font="h200"
                    component="h4">
                    {title}
                </Heading>
                <Text
                    component="p"
                    font="h200"
                    className={cls(styles.percentage, 'text-primary')}>
                    {`${percentage}%`}
                </Text>
            </div>
            <div>
                <svg viewBox="0 0 63.66 63.66" className={styles.pie}>
                    <circle
                        r="25%"
                        cx="50%"
                        cy="50%"
                        style={{
                            strokeDasharray: `${percentage} 100`,
                        }}></circle>
                </svg>
            </div>
        </div>
    );
};

export default CardChart;
