import React from 'react';
import { Container, Section, cls } from '@solublestudio/soluto-design-system';

import TitleValue from '@ds/components/shared/TitleValueBase/Default';
import type { TitleValueProps } from '@ds/components/shared/TitleValueBase/Default';

import SectionsTitle, {
    SectionsTitleProps,
} from '@ds/components/shared/SectionsTitle';

import styles from './styles.module.scss';

export interface TitleValueItemsProps
    extends Pick<TitleValueProps, 'title' | 'description'> {}

export interface TitleValueSectionProps
    extends Omit<SectionsTitleProps, 'component' | 'className' | 'link'> {
    title?: SectionsTitleProps['title'];
    subtitle?: SectionsTitleProps['subtitle'];
    items: TitleValueItemsProps[];
}

const TitleValueSection: React.FC<TitleValueSectionProps> = ({
    title,
    subtitle,
    items,
}) => {
    return (
        <Section className={cls('pb-mega')}>
            <Container>
                {title || subtitle ? (
                    <SectionsTitle
                        title={title}
                        subtitle={subtitle}
                        className={subtitle ? '' : 'mb-xxxl'}
                    />
                ) : null}
                {items.map((item, i) => (
                    <TitleValue
                        {...item}
                        key={i}
                        className={cls(
                            styles.item,
                            items[i + 1] && 'pb-xxl',
                            items[i - 1] && 'pt-xxl',
                        )}
                        data-sal="slide-up"
                        data-sal-delay={i * 200}
                    />
                ))}
            </Container>
        </Section>
    );
};

export default TitleValueSection;
