import React from 'react';

import {
    Section,
    Row,
    Col,
    cls,
    Container,
} from '@solublestudio/soluto-design-system';

import Link from '@ds/components/shared/Link';
import Text from '@ds/components/shared/Text';
import Heading from '@ds/components/shared/Heading';

import styles from './styles.module.scss';

type Anchor = {
    label: string;
    href: string;
};

export interface PreFooterContactProps {
    title: string;
    tel?: Anchor;
    email?: Anchor;
    information?: string;
}

const PreFooterContact = ({
    title,
    email,
    tel,
    information,
}: PreFooterContactProps) => {
    return (
        <Section className="pb-huge">
            <Container>
                <Row>
                    <Col
                        col={{ lg: 5 }}
                        data-sal="slide-down"
                        data-sal-delay="400"
                        data-sal-duration="400"
                    >
                        <Heading
                            component="h2"
                            font="h300"
                            className={cls('mb-xxl')}>
                            {title}
                        </Heading>
                    </Col>
                    <Col
                        col={{ lg: 6 }}
                        offset={{ lg: 1 }} 
                        data-sal="slide-down"
                        data-sal-delay="400"
                        data-sal-duration="400"
                    >
                        {tel && (
                            <div className={cls('mb-xxs mt-xxs')}>
                                <Link font="b400" href={tel?.href}>
                                    {tel.label}
                                </Link>
                            </div>
                        )}
                        {email && (
                            <div className={cls('mb-lg')}>
                                <Link href={email?.href} font="b400">
                                    {email.label}
                                </Link>
                            </div>
                        )}
                        {information && (
                            <Text
                                addBreaklines={false}
                                component="div"
                                font="b400"
                                className={styles.text}>
                                {information}
                            </Text>
                        )}
                    </Col>
                </Row>
            </Container>
        </Section>
    );
};

export default PreFooterContact;
