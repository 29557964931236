import React from 'react';

// Brand Center Cards
import CardInfo from '@ds/components/shared/Cards/Info';
import CardBeforeAfter from '@ds/components/shared/Cards/BeforeAndAfter';
import CardBgImageLarge from '@ds/components/shared/Cards/BgImage/LargeCTA';
import CardMediaText from '@ds/components/shared/Cards/Media/Text';
import CardMediaImage from '@ds/components/shared/Cards/Media/Image';
import CardNoGutter from '@ds/components/shared/Cards/NoGutter';
import CardDoDont from '@ds/components/shared/Cards/DoDont';
import CardColor from '@ds/components/shared/Color/Color';
import CardPicture from '@ds/components/shared/Cards/Picture';
import CardImageLegend from '@ds/components/shared/Cards/ImageLegend';
import CardDownload from '@ds/components/shared/Cards/Download';
import CardVideo from '@ds/components/shared/Cards/Video';
import CardIcon from '@ds/components/shared/Cards/Icon';
import CardDownloadInside from '@ds/components/shared/Cards/DownloadInside';
import CardFigma from '@ds/components/shared/FigmaEmbed';

export type Component = {
    typename: string;
    id?: string;
    originalId?: string;
    [key: string]: any;
};

const Components: { [key: string]: React.ElementType } = {
    card_info: CardInfo,
    card_before_after: CardBeforeAfter,
    card_bg_image_large: CardBgImageLarge,
    card_media_text: CardMediaText,
    card_media_image: CardMediaImage,
    card_no_gutter: CardNoGutter,
    card_do_dont: CardDoDont,
    card_picture: CardPicture,
    card_color: CardColor,
    card_image_legend: CardImageLegend,
    card_download: CardDownload,
    card_video: CardVideo,
    card_icon: CardIcon,
    card_download_inside: CardDownloadInside,
    card_figma: CardFigma,
};

const DynamicCard: React.FC<Component> = ({
    typename,
    id,
    originalId,
    ...props
}) => {
    if (typeof Components[typename] !== 'undefined') {
        const Component = Components[typename];

        return <Component {...props} key={id ?? originalId} />;
    }

    return (
        <code>
            The component <strong>{typename}</strong> has not been created yet.
        </code>
    );
};

export default DynamicCard;
