import React from 'react';

import { cls, Container } from '@solublestudio/soluto-design-system';

import Text from '@ds/components/shared/Text';

import styles from './styles.module.scss';

export type StatProps = {
    number: string;
    title: string;
};

export interface StatsProps {
    stats: StatProps[];
    className?: string;
}

const Stat: React.FC<StatsProps> = ({ stats, className }) => {
    return (
        <Container className={cls(styles.wrapper, className)}>
            <ul
                className={cls('list-unstyled d-flex', styles.list)}
                data-sal="slide-left-children">
                {stats.map((number, index) => {
                    return (
                        <li
                            data-sal="slide"
                            data-sal-delay={400 + index * 400}
                            key={index}
                            className={cls(
                                'd-flex flex-column pr-xxl',
                                styles.item,
                            )}>
                            <Text font="h500">{number.number}</Text>
                            <Text font="h100" className={styles.numberTitle}>
                                {number.title}
                            </Text>
                        </li>
                    );
                })}
            </ul>
        </Container>
    );
};

export default Stat;
