import React from 'react';

import Picture, { PictureProps } from 'Picture';
import CardMediaBase, { CardMediaBaseProps } from '..';

export interface CardMediaImageProps extends CardMediaBaseProps {
    image: PictureProps;
}

const CardMediaImage = ({ image, ...props }: CardMediaImageProps) => {
    return (
        <CardMediaBase {...props}>
            <Picture
                image={image}
                layout="background"
                objectFit="cover"
                alt={image?.alt ?? undefined}
            />
        </CardMediaBase>
    );
};

export default CardMediaImage;
