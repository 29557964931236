import React, {
    useRef,
    useEffect,
    forwardRef,
    useImperativeHandle,
} from 'react';

import { cls } from '@solublestudio/soluto-design-system';

interface PerformantVideoProps extends React.HTMLProps<HTMLVideoElement> {
    className?: string;
    sources?: {
        src: string;
        type: string;
    }[];
}

const PerformantVideo = forwardRef(function PerformantVideo(
    { className, autoPlay, sources = [], ...props }: PerformantVideoProps,
    ref,
) {
    const videoRef = useRef<HTMLVideoElement>(null);

    useImperativeHandle(
        ref,
        () => ({
            play: () => {
                videoRef.current?.play();
            },
            load: () => {
                videoRef.current?.load();
            },
        }),
        [],
    );

    useEffect(() => {
        if (
            !videoRef?.current ||
            typeof IntersectionObserver === 'undefined' ||
            !autoPlay
        ) {
            return;
        }

        let observer: any = null;

        observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    videoRef.current?.play();
                    observer.disconnect();
                }
            },
            {
                threshold: 0,
            },
        );

        observer.observe(videoRef.current);

        return () => {
            observer.disconnect();
        };
    }, [autoPlay, videoRef]);

    return (
        <video
            ref={videoRef}
            className={cls(className)}
            {...props}
            playsInline
            muted
            loop>
            {sources?.map((s) => (
                <source key={s.src} src={s.src} type={`video/${s.type}`} />
            ))}
        </video>
    );
});

export default PerformantVideo;
