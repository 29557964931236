import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';
import Text from '@ds/components/shared/Text';

import styles from './styles.module.scss';
import CopyToClipboard from './CopyToClipboard';

export interface ColorThemeProps {
    themeLight?: boolean;
}

export interface ColorProps extends ColorThemeProps {
    name: string;
    color: string;
    hex: string;
    rgb: string;
    cmyk: string;
    pantone?: string;
    ral?: string;
}

const Color = ({
    name,
    color,
    hex,
    rgb,
    cmyk,
    pantone,
    ral,
    themeLight,
}: ColorProps) => {
    const values: { [key: string]: string | undefined } = {
        hex,
        rgb,
        cmyk,
        pantone,
        ral,
    };

    const keys = Object.keys(values) ?? [];

    return (
        <div
            style={{ backgroundColor: color }}
            className={cls(
                styles.color,
                themeLight ? 'text-base-1000' : 'text-base-000',
                'd-flex',
                'flex-column',
                'justify-content-between',
                'p-lg',
            )}>
            <Text
                font={'h200'}
                className={cls(styles.colorName)}
                component="div">
                {name}
            </Text>
            <ul
                className={cls(
                    styles.valuesList,
                    'list-unstyled',
                    'mb-none',
                    'text-uppercase',
                )}>
                {keys.map((key: string, i: number) => {
                    const value = values?.[key as string];

                    if (value) {
                        return (
                            <li key={`color-item-${i}`}>
                                <Text
                                    font="b300"
                                    component="div"
                                    className={cls(styles.value)}>
                                    {value}
                                </Text>
                                <CopyToClipboard
                                    btnClassName={cls(styles.valueBtn)}
                                    value={value}
                                    themeLight={themeLight}
                                />
                            </li>
                        );
                    }
                    return null;
                })}
            </ul>
        </div>
    );
};

export default Color;
