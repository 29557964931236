import React, { useMemo, useState } from 'react';

import { cls, Col, Row } from '@solublestudio/soluto-design-system';

import Text, { TextProps } from '@ds/components/shared/Text';
import Heading, { HeadingProps } from '@ds/components/shared/Heading';
import CardContent from '@ds/components/shared/Cards/Content';
import Pagination, { PaginationProps } from '@ds/components/shared/Pagination';
import usePagination from '@ds/hooks/usePagination';

type ListPaginationProps = {
    pages: PaginationProps['pages'];
    activePage: PaginationProps['activePage'];
    nextLabel?: PaginationProps['nextLabel'];
    previousLabel?: PaginationProps['previousLabel'];
    pagesDepth: PaginationProps['pagesDepth'];
    itemsPerPage: number;
};

export interface GridListPaginationProps {
    title?: string;
    description?: string;
    items: any[];
    pagination: ListPaginationProps;
    ComponentCard: React.FC<any>;
    itemsPerRow?: number;
    titleProps?: HeadingProps;
    descriptionProps?: TextProps;
    classes?: {
        element?: string;
        pagination?: string;
    };
}

const GridListPagination: React.FC<GridListPaginationProps> = ({
    title,
    titleProps,
    descriptionProps,
    description,
    items,
    itemsPerRow = 3,
    pagination,
    ComponentCard = CardContent,
    classes = {
        element: '',
        pagination: '',
    },
}) => {
    const { pages, ...restPagination } = pagination ?? {};

    const getIsHidden = (index: number) => {
        if (!restPagination || !restPagination?.activePage) {
            return false;
        }

        return (
            index <
                (restPagination?.activePage - 1) * pagination.itemsPerPage ||
            index >= restPagination?.activePage * pagination.itemsPerPage
        );
    };

    return (
        <>
            <Row>
                <Col cols={{ xs: 12, lg: 8 }}>
                    {title ? (
                        <Heading font="h500" component="h1" {...titleProps}>
                            {title}
                        </Heading>
                    ) : null}
                    {description ? (
                        <Text
                            component="p"
                            font="b400"
                            className="pt-sm"
                            {...descriptionProps}>
                            {description}
                        </Text>
                    ) : null}
                </Col>
            </Row>
            <Row
                className={cls(
                    items?.length > 0
                        ? pages > 1
                            ? 'mb-huge pb-huge'
                            : 'mb-huge'
                        : '',
                )}>
                {items?.length > 0 &&
                    items.map((item, i) => (
                        <Col
                            col={{
                                lg: itemsPerRow > 0 ? 12 / itemsPerRow : 3,
                            }}
                            className={cls(
                                'mt-xxxl',
                                getIsHidden(i) ? 'd-none' : 'd-block',
                                classes?.element,
                            )}
                            key={`grid-${item?.type}-item-${item?.id ?? i}`}>
                            <ComponentCard {...item} />
                        </Col>
                    ))}
            </Row>
            {pages > 1 ? (
                <div
                    className={cls(
                        'mt-huge',
                        'd-flex',
                        'justify-content-center',
                        classes?.pagination,
                    )}>
                    <Pagination pages={pages} {...restPagination} />
                </div>
            ) : null}
        </>
    );
};

export default GridListPagination;
