import React from 'react';
import { cls } from '@solublestudio/soluto-design-system';

import CarouselCardsSection from '@ds/components/organisms/CarouselCardsSection';

import type { CarouselCardsSectionProps } from '@ds/components/organisms/CarouselCardsSection';

import { LinkProps } from 'Link';

export interface BlogPostsSectionProps
    extends Omit<CarouselCardsSectionProps, 'size'> {
    data: CarouselCardsSectionProps['items'];
    title: CarouselCardsSectionProps['title'];
    size?: 'small' | 'large' | 'double' | 'quadruple' | 'quadruple-small';
    cta?: LinkProps;
}

const BlogPostsSection = ({
    title,
    data,
    size = 'large',
    cta,
    ...props
}: BlogPostsSectionProps) => {
    const sizes = {
        small: 'small',
        large: 'large',
        double: 'double',
        quadruple: 'quadruple',
        'quadruple-small': 'quadruple',
    };

    return (
        <CarouselCardsSection
            {...props}
            {...(!!cta && { sliderCardsControls: false })}
            className={cls('pb-mega')}
            items={data}
            subtitle={title}
            size={sizes[size] as CarouselCardsSectionProps['size']}
            link={cta}
            subtitleCols={12}
        />
    );
};

export default BlogPostsSection;
