import React from 'react';

import { Icon, cls } from '@solublestudio/soluto-design-system';

import Text from '@ds/components/shared/Text';

import Picture, { PictureProps } from 'Picture';

export interface CardDoDontProps {
    className?: string;
    dontStyle?: boolean;
    description: string;
    image: PictureProps;
}

const CardDoDont = ({
    className,
    dontStyle,
    description,
    image,
}: CardDoDontProps) => {
    return (
        <div>
            <div
                className={cls(
                    dontStyle ? 'bg-dont' : 'bg-base-400',
                    'position-relative',
                    'py-xl',
                    'px-xxxl',
                    'mb-lg',
                    className,
                )}>
                <Picture
                    image={image}
                    alt={image?.alt ?? undefined}
                    layout="full"
                    aspectRatio={0.487}
                    objectFit="cover"
                />
            </div>
            <Icon
                name={dontStyle ? 'crossCircle' : 'checkCircle'}
                size={24}
                classes={{
                    icon: cls(
                        dontStyle ? 'text-error' : 'text-success',
                        'mb-xs',
                    ),
                }}
            />
            <Text component="div" font="b100">
                {description}
            </Text>
        </div>
    );
};

export default CardDoDont;
