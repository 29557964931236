import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';
import CardBgImageBase, {
    CardBgImageBaseProps,
} from '@ds/components/shared/Cards/BgImage';
import Button from '@ds/components/shared/Button';

import type { CRVButtonProps } from '@ds/components/shared/Button';

import styles from './styles.module.scss';

export interface CardBgImageLargeCTAProps extends CardBgImageBaseProps {
    button: CRVButtonProps;
}

const CardBgImageLargeCTA = ({
    button,
    ...props
}: CardBgImageLargeCTAProps) => {
    return (
        <CardBgImageBase
            {...props}
            classes={{
                wrapper: cls(styles.card),
                title: cls('text-base-200'),
            }}
            titleFont="b200"
            categoryFont="h200">
            <Button
                {...button}
                className={cls('mt-auto', 'position-relative')}
                iconName="chevronCircleRight"
                variant="primary-small"
            />
        </CardBgImageBase>
    );
};

export default CardBgImageLargeCTA;
