import React from 'react';

import {
    Section,
    Row,
    Col,
    cls,
    Container,
} from '@solublestudio/soluto-design-system';

import CardResource, {
    CardResourceProps,
} from '@ds/components/shared/Cards/Resource';

export interface BannerResourceProps {
    resource: CardResourceProps;
}

const BannerResource = ({ resource }: BannerResourceProps) => {
    return (
        <Section className="pb-huge">
            <Container>
                <Row>
                    <Col col={{ xs: 12, lg: 8 }} offset={{ lg: 4 }}>
                        <CardResource {...resource} horizontal />
                    </Col>
                </Row>
            </Container>
        </Section>
    );
};

export default BannerResource;
