import React, { useCallback } from 'react';

import { Image, cls } from '@solublestudio/soluto-design-system';

import type { ImageProps } from '@solublestudio/soluto-design-system';

import Link from '@ds/components/shared/Link';

import styles from './styles.module.scss';

export interface CardIconProps {
    icon: ImageProps;
    linkLabel?: string;
}

const CardIcon = ({ icon, linkLabel = 'Descargar' }: CardIconProps) => {
    const download = useCallback(
        (e) => {
            e.preventDefault();

            if (!icon?.src) {
                return;
            }

            fetch(`${icon.src}`)
                .then((response) => response.blob())
                .then((blob) => {
                    const blobURL = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = blobURL;
                    a.style = 'display: none';

                    if (icon.filename?.length) a.download = icon.filename;
                    document.body.appendChild(a);
                    a.click();
                })
                .catch(() => {});
        },
        [icon],
    );

    return (
        <Link
            href={icon?.src}
            onClick={download}
            font="b100"
            alt={linkLabel}
            className={cls(styles.link, 'd-block')}>
            <div
                className={cls(
                    styles.wrapper,
                    'bg-base-400',
                    'text-base-000',
                    'd-flex',
                    'align-items-center',
                    'justify-content-center',
                )}>
                <Image src={icon?.src} />
            </div>
            <span
                className={cls(
                    styles.label,
                    'pt-tiny',
                    'd-none',
                    'd-lg-inline-block',
                )}>
                {linkLabel}
            </span>
        </Link>
    );
};

export default CardIcon;
