import React from 'react';

import { Section, cls, Container } from '@solublestudio/soluto-design-system';

import Stats, { StatProps } from './components/Stats';
import SectionsTitle, {
    SectionsTitleProps,
} from '@ds/components/shared/SectionsTitle';
export interface StatsSectionProps extends SectionsTitleProps {
    stats: StatProps[];
}

const StatsSection: React.FC<StatsSectionProps> = ({ stats, title }) => {
    return (
        <Section className="pb-mega">
            <Container>
                <SectionsTitle title={title} />
            </Container>
            <Stats stats={stats} className={cls('mt-xxl')} />
        </Section>
    );
};

export default StatsSection;
