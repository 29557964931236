import React from 'react';

import {
    Section,
    cls,
    Container,
    Row,
    Col,
} from '@solublestudio/soluto-design-system';

import { navigate } from 'Link';

import MediaBackgroundComponent, {
    MediaBackgroundComponentProps,
} from '@ds/components/shared/MediaBackgroundComponent';

import CardInfoPost, {
    CardInfoPostProps,
} from '@ds/components/shared/Blog/CardInfoPost';

import { withSolutoPrefix } from '@solublestudio/soluto-design-system/src/utils/link';

import styles from './styles.module.scss';

export interface HighlightedPostSectionProps
    extends Omit<CardInfoPostProps, 'withCard'> {
    background: MediaBackgroundComponentProps['background'];
}

const HighlightedPostSection = ({
    title,
    category,
    topics,
    time,
    background,
    slug,
}: HighlightedPostSectionProps) => {
    const onClick = () => {
        navigate(withSolutoPrefix(slug));
    };

    return (
        <Section className={cls('mb-mega', styles.highlightedPostSection)}>
            <div
                onClick={onClick}
                className={cls(
                    'pb-lg position-relative d-flex justify-content-end align-items-center',
                    styles.wrapperImage,
                )}>
                {background && (
                    <MediaBackgroundComponent
                        bgCover
                        background={{ isImage: true, ...background }}
                    />
                )}
                <Container>
                    <Row>
                        <Col col={{ lg: 8 }}>
                            <CardInfoPost
                                category={category}
                                title={title}
                                topics={topics}
                                time={time}
                                withCard={true}
                                slug={slug}
                                dataTheme="light"
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </Section>
    );
};

export default HighlightedPostSection;
