import React, { CSSProperties, useMemo } from 'react';
import { cls } from '@solublestudio/soluto-design-system';

import styles from './styles.module.scss';

type IframeRatio = 'horizontal' | 'square' | 'vertical';

const EMBED_URL = 'https://www.figma.com/embed?embed_host=share&url=';

const ratios: { [key in IframeRatio]: string } = {
    horizontal: '56.25%',
    square: '100%',
    vertical: '133%',
};

export type FigmaEmbedProps = {
    /**
     * src for figma embed. Click on Share button in Figma and click on 'Get embed code' and then copy the value of the src attribute from the iframe.
     */
    iframeSrc: string;
    /**
     * add lazy loading
     */
    loading?: 'lazy' | 'eager';
    /**
     * width of iframe
     */
    width?: string;
    /**
     * height of iframe
     */
    height?: string;
    aspectRatio: 'horizontal' | 'square' | 'vertical';
} & React.HTMLAttributes<HTMLIFrameElement>;

export default function FigmaEmbed({
    iframeSrc,
    loading = 'lazy',
    width = '100%',
    height = '100%',
    className,
    aspectRatio = 'horizontal',
}: FigmaEmbedProps) {
    const wrapperStyle = {
        '--iframe-ratio': ratios[aspectRatio],
    } as CSSProperties;

    const src = useMemo(() => `${EMBED_URL}${iframeSrc}`, [iframeSrc]);

    return (
        <div className={styles.wrapperIframe} style={wrapperStyle}>
            <iframe
                title="figma-embed"
                className={cls(styles.iframe, className)}
                width={width}
                height={height}
                src={src}
                loading={loading}
            />
        </div>
    );
}
