import React, { memo } from 'react';

import {
    Pagination as SolutoPagination,
    PaginationProps as PaginationDSProps,
} from '@solublestudio/soluto-design-system';

import styles from './styles.module.scss';
import Link from 'Link';

export interface PaginationProps extends Omit<PaginationDSProps, 'classes'> {}

const Pagination = (props: PaginationDSProps) => {
    return (
        <SolutoPagination
            {...props}
            component={Link}
            classes={{
                item: styles.item,
                disabled: styles.disabled,
                wrapper: styles.wrapper,
                arrow: styles.arrow,
                active: styles.active,
                link: styles.link,
            }}
        />
    );
};

export default memo(Pagination);
