import React, { useMemo } from 'react';

import { cls } from '@solublestudio/soluto-design-system';

import Picture from 'Picture';

import PerformantVideo from '../PerformantVideo';

import styles from './styles.module.scss';

export interface MediaBackgroundComponentProps {
    background: any;
    classes?: {
        video?: string;
        imgWrapper?: string;
        img?: string;
        wrapper?: string;
    };
    withDelay?: boolean;
    bgCover?: boolean;
    withPriority?: boolean;
}

const MediaBackgroundComponent = ({
    background,
    classes,
    withDelay = true,
    bgCover,
    withPriority = false,
}: MediaBackgroundComponentProps) => {
    const thisImage = useMemo(() => {
        if (!background?.isImage) return {};

        const dataImage =
            background?.gatsbyImageData ??
            background?.file?.childImageSharp?.gatsbyImageData;

        return {
            src: dataImage.images?.fallback?.src ?? background?.url,
            alt: background?.alt,
            height: dataImage?.height,
            width: dataImage?.width,
            srcSet: dataImage.images?.fallback?.srcSet,
            sizes: dataImage.images?.fallback?.sizes,
        };
    }, [background]);

    return (
        <div
            className={cls(
                styles.wrapper,
                bgCover && styles.wrapperCover,
                classes?.wrapper,
            )}
            {...(withDelay && {
                'data-sal': 'fade',
                'data-sal-delay': '1200',
                'data-sal-duration': '1200',
            })}>
            {!!background &&
                (background?.isImage ? (
                    // eslint-disable-next-line jsx-a11y/alt-text
                    <img
                        {...thisImage}
                        loading={withPriority ? 'eager' : 'lazy'}
                        className={cls(styles.img, classes?.img)}
                    />
                ) : (
                    <PerformantVideo
                        className={cls(styles.video, classes?.video)}
                        sources={[
                            {
                                src: background.url,
                                type: background?.extension,
                            },
                        ]}
                        autoPlay
                        preload={withPriority ? 'auto' : 'metadata'}
                    />
                ))}
        </div>
    );
};

export default MediaBackgroundComponent;
