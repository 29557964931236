import React from 'react';

import CardBgImageLarge from '@ds/components/shared/Cards/BgImage/Large';
import CardMoreInfo from '@ds/components/shared/Cards/MoreInfo';
import CardChart from '@ds/components/shared/Cards/Chart';
import CardContent from '@ds/components/shared/Cards/Content';
import CardDownload from '@ds/components/shared/Cards/DownloadInside';
import CardYear from '@ds/components/shared/Cards/Year';
import CardGraphic from '@ds/components/shared/Cards/Graphic';
import CardResource from '@ds/components/shared/Cards/Resource';
import CardVideoPlaylist from '@ds/components/shared/Cards/CardVideoPlaylist';

export type Component = {
    typename: string;
    id?: string;
    originalId?: string;
    internal?: { type: string };

    [key: string]: any;
};

const Components: { [key: string]: React.ElementType } = {
    DatoCmsDistributor: CardBgImageLarge,
    DatoCmsOffice: CardMoreInfo,
    DatoCmsCardMoreInfo: CardMoreInfo,
    DatoCmsChart: CardChart,
    DatoCmsBgImageLarge: CardChart,
    HubspotBlogLarge: CardBgImageLarge,
    HubspotBlog: CardContent,
    DatoCmsCardDownload: CardDownload,
    DatoCmsCardContent: CardContent,
    DatoCmsCardYear: CardYear,
    DatoCmsCardGraphic: CardGraphic,
    HubspotLanding: CardResource,
    DatoCmsCardVideo: CardVideoPlaylist,
};

const DynamicCarouselCard: React.FC<Component> = ({
    typename: __typename,
    id,
    originalId,
    internal = {},
    i,
    ...props
}) => {
    const typename = __typename ?? internal?.type ?? '';

    if (typeof Components[typename] !== 'undefined') {
        const Component = Components[typename];

        return (
            <Component
                {...props}
                typename={typename}
                key={`${typename}-${id}`}
            />
        );
    }

    return (
        <code>
            The component <strong>{typename}</strong> has not been created yet.
        </code>
    );
};

export default DynamicCarouselCard;
