import Input from '@ds/components/shared/Form/Input';
import { Col, Container, Row, cls } from '@solublestudio/soluto-design-system';
import React, { useEffect, useRef } from 'react';

import Link, { LinkProps } from '@ds/components/shared/Link';
import Text from '@ds/components/shared/Text';

import Topics from '@ds/components/shared/Blog/Topics';
import CardContent from '@ds/components/shared/Cards/Content';

import GridListPagination, {
    GridListPaginationProps,
} from '../GridListPagination';

import styles from './styles.module.scss';

const debounce = (func: Function, wait: number) => {
    let timeout: NodeJS.Timeout;
    return function (this: any, ...args: any[]) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait);
    };
};

interface BlogSearchSectionProps extends GridListPaginationProps {
    value: string;
    onChange: (value: string) => void;
    topics: LinkProps[];
    highlights: LinkProps[];
    onSubmit?: (values: any) => void;
    highlightsTitle: string;
    inputLabel?: string;
    sticky?: boolean;
    isLoading?: boolean;
}

const BlogSearchSection: React.FC<BlogSearchSectionProps> = ({
    value,
    onChange,
    highlights,
    topics,
    onSubmit,
    highlightsTitle,
    title,
    items,
    pagination,
    inputLabel = 'Search',
    titleProps,
    sticky = false,
    isLoading,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const thisOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const formValues = new FormData(e.currentTarget);

        let values = Object.fromEntries(formValues.entries());

        if (typeof onSubmit === 'function') {
            onSubmit(values);
        }
    };

    useEffect(() => {
        if (
            !ref?.current ||
            typeof IntersectionObserver === 'undefined' ||
            typeof document === 'undefined'
        ) {
            return;
        }

        /// Observer when element has a sticky behavior
        const header = document.querySelector('header');
        let top = header?.clientHeight || 0;

        const togglePinned = debounce((e) => {
            (e.target as HTMLDivElement).dataset.pinned =
                e.intersectionRatio < 1 ? 'true' : 'false';
        }, 150);

        const observer = new IntersectionObserver(
            ([e]) => {
                togglePinned(e);
            },
            {
                threshold: [1],
                rootMargin: `${(top + 1) * -1}px 0px 0px 0px`,
            },
        );

        observer.observe(ref.current);

        return () => {
            if (observer && ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);

    return (
        <section className={cls(sticky && [styles.sticky, 'mb-xxl'])} ref={ref}>
            <Container>
                <Row>
                    <Col
                        col={{ xs: 12 }}
                        className={cls(
                            styles.searchContainer,
                            !sticky && [
                                topics?.length > 0 ? 'mb-xxxl' : '',
                                'pt-huge',
                            ],
                        )}>
                        <form onSubmit={thisOnSubmit}>
                            <Input
                                placeholder={inputLabel}
                                defaultValue={value}
                                onChange={onChange}
                                name="search"
                                iconName="search"
                                rightIcon="arrowUpRight"
                                classes={{
                                    wrapper: cls(
                                        sticky &&
                                            styles.searchInputWrapperSticky,
                                    ),
                                    input: cls(
                                        sticky && styles.searchInputSticky,
                                    ),
                                }}
                            />
                        </form>

                        {topics?.length > 0 && (
                            <Topics
                                topics={topics}
                                size="small"
                                className={cls('mt-lg', styles.topics)}
                                limit={0}
                            />
                        )}
                    </Col>
                </Row>

                <GridListPagination
                    title={title}
                    titleProps={{
                        ...titleProps,
                        font: 'h300',
                    }}
                    items={items}
                    pagination={pagination}
                    ComponentCard={(item) => (
                        <CardContent {...item} withBg={false} />
                    )}
                    itemsPerRow={4}
                    classes={{
                        pagination: cls('mb-huge'),
                    }}
                />

                {highlights?.length > 0 &&
                    !isLoading &&
                    items?.length === 0 && (
                        <Row className="mt-xxxl mb-huge">
                            <Col col={{ xs: 12 }}>
                                <Text
                                    font="b400"
                                    className={cls(
                                        'mb-lg mr-xs text-base-200',
                                    )}>
                                    {highlightsTitle}
                                </Text>

                                <ul
                                    className={cls(
                                        'd-inline-flex',
                                        styles.highlightsList,
                                    )}>
                                    {highlights.map((highlight) => (
                                        <li
                                            key={`${highlight?.label}-${highlight?.href}`}>
                                            <Link
                                                href={highlight?.href}
                                                font="b400">
                                                {highlight?.label}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </Col>
                        </Row>
                    )}
            </Container>
        </section>
    );
};

export default BlogSearchSection;
