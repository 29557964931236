import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';

import Text from '@ds/components/shared/Text';
import SocialIcons from '@ds/components/shared/SocialIcons';

import styles from './styles.module.scss';

export interface BlogShareProps {
    className?: string;
    label: string;
    socialIcons: string[];
    post?: {
        title: string;
        url: string;
    };
    successMessage?: string;
}

const BlogShare = ({
    className,
    label,
    socialIcons,
    post,
    successMessage,
}: BlogShareProps) => {
    const canShare =
        typeof window !== 'undefined' && typeof navigator?.share === 'function';

    const social: { [key: string]: string } = {
        facebook: `https://www.facebook.com/sharer/sharer.php?u=${post?.url}`,
        twitter: `https://twitter.com/intent/tweet?url=${
            post?.url
        }&text=${encodeURI(post?.title as string)}`,
        whatsapp: `whatsapp://send?text=${post?.url}`,
        email: `mailto:?subject=${post?.title}&body=${post?.url}`,
        linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${post?.url}`,
        ...(canShare ? { share: '/#' } : { link: '/#' }),
    };

    const icons = socialIcons
        .map((name) => {
            const url = social?.[name as string];

            if (!url) {
                return null;
            }

            return {
                name: name,
                url: url,
                classes: {
                    list:
                        name === 'link' &&
                        cls('position-relative', styles.socialIcon),
                },
                onClick:
                    url === '/#'
                        ? async (e: React.MouseEvent<HTMLAnchorElement>) => {
                              try {
                                  e.preventDefault();
                                  if (canShare) {
                                      navigator.share({
                                          title: post?.title,
                                          url: post?.url,
                                      });
                                  } else if (
                                      typeof window !== 'undefined' &&
                                      navigator?.clipboard
                                  ) {
                                      await navigator.clipboard.writeText(
                                          post?.url as string,
                                      );

                                      const existsTooltip =
                                          document.getElementById('tooltip');

                                      if (!existsTooltip) {
                                          let span =
                                              document.createElement('span');

                                          span.innerText = successMessage ?? '';

                                          span.className = styles.tooltip;

                                          span.id = 'tooltip';

                                          span.setAttribute(
                                              'data-theme',
                                              'dark',
                                          );

                                          (
                                              e.target as HTMLElement
                                          ).parentNode?.insertBefore(
                                              span,
                                              e.target as HTMLElement,
                                          );

                                          setTimeout(() => {
                                              span.remove();
                                          }, 2500);
                                      }
                                  }
                              } catch (error) {
                                  console.log(error);
                              }
                          }
                        : undefined,
            };
        })
        .filter(Boolean);

    return (
        <div className={cls('d-flex', 'align-items-center', className)}>
            <Text className={cls('mr-sm')} component="span" font="b100">
                {label}
            </Text>
            <SocialIcons icons={icons} size={24} gap="xxs" />
        </div>
    );
};

export default BlogShare;
