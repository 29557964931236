import React from 'react';

import HeroSection, {
    HeroSectionProps,
} from '@ds/components/organisms/HeroSection';

import type { PictureProps } from 'Picture';

import bgImage from '@ds/img/bg-error.jpg';

import { CRVButtonProps } from '@ds/components/shared/Button';

export interface ErrorSectionProps {
    title: HeroSectionProps['title'];
    text: HeroSectionProps['text'];
    withDelay?: HeroSectionProps['withDelay'];
    cta?: { label?: CRVButtonProps['label']; href?: string };
    background?: PictureProps['image'];
    bgCover?: boolean;
    theme?: string;
}

const ErrorSection = ({
    title,
    text,
    cta = {},
    background,
    withDelay,
    bgCover = true,
    theme = 'dark',
}: ErrorSectionProps) => {
    return (
        <HeroSection
            title={title}
            text={text}
            ctas={[
                {
                    label: cta?.label ?? 'Volver a la home',
                    href: cta?.href ?? '/',
                },
            ]}
            background={
                background ?? {
                    file: bgImage,
                    isImage: true,
                }
            }
            bgCover={bgCover}
            theme={theme}
            align="center"
            withDelay={withDelay}
        />
    );
};

export default ErrorSection;
