import React from 'react';

import { Section, Container } from '@solublestudio/soluto-design-system';

import CardContent from '@ds/components/shared/Cards/Content';

import GridListPagination, {
    GridListPaginationProps,
} from '../GridListPagination';

export interface GridListSectionProps extends GridListPaginationProps {}

const GridListSection: React.FC<GridListSectionProps> = ({
    title,
    description,
    items,
    itemsPerRow = 3,
    pagination,
    ComponentCard = CardContent,
}) => {
    return (
        <Section className="pb-xxxl pt-mega">
            <Container>
                <GridListPagination
                    title={title}
                    description={description}
                    items={items}
                    pagination={pagination}
                    ComponentCard={ComponentCard}
                    itemsPerRow={itemsPerRow}
                />
            </Container>
        </Section>
    );
};

export default GridListSection;
