import React from 'react';

import {
    cls,
    Section,
    Container,
    Text,
    Icon,
} from '@solublestudio/soluto-design-system';

import Picture, { PictureProps } from 'Picture';

import { useModal } from '@ds/hooks/useModal';

import styles from './styles.module.scss';

interface Playlist {
    image: PictureProps['image'];
    title: string;
    date: string;
    duration: string;
    videoId: string;
}

export interface AllVideosSectionProps {
    title: string;
    items: Playlist[];
}

function AllVideosSection({ title, items = [] }: AllVideosSectionProps) {
    const { openModal } = useModal();

    return (
        <Section className="pb-huge">
            <Container>
                {title ? (
                    <Text
                        component="div"
                        className={cls('pb-lg', styles.title)}>
                        {title}
                    </Text>
                ) : null}
            </Container>

            {items.map((item, index) => (
                <Container
                    key={`video-item-${item?.title}-${index}`}
                    className={cls(styles.container)}>
                    <div className={cls('d-flex flex-column', styles.item)}>
                        <div className={cls('d-flex', styles.description)}>
                            <div
                                tabindex="0"
                                role="button"
                                onClick={() => openModal(item.videoId)}>
                                <Picture
                                    image={item?.image}
                                    classes={{
                                        wrapper: cls('mr-xl'),
                                    }}
                                />
                            </div>
                            <div
                                tabindex="0"
                                role="button"
                                onClick={() => openModal(item.videoId)}>
                                <Text
                                    component="p"
                                    font="b200"
                                    className={cls(styles.itemTitle)}>
                                    {item?.title}
                                </Text>
                            </div>
                        </div>
                        <div className={cls('d-flex', styles.info)}>
                            <div className={cls('d-flex', styles.text)}>
                                <Text component="p" className="text-capitalize">
                                    {item?.date}
                                </Text>
                                <Text component="p">{item?.duration}</Text>
                            </div>

                            <button
                                className={cls(styles.button)}
                                onClick={() => openModal(item.videoId)}>
                                <Icon
                                    name="play"
                                    size={16}
                                    classes={{
                                        icon: cls(styles.buttonIcon),
                                    }}
                                />
                            </button>
                        </div>
                    </div>
                    <div className={cls(styles.borderBottom)} />
                </Container>
            ))}
        </Section>
    );
}

export default AllVideosSection;
