import React from 'react';

import useIsMobile from '@ds/hooks/useIsMobile';

import Picture, { PictureProps } from 'Picture';

export interface CardPictureProps {
    image: PictureProps;
    imageMobile?: PictureProps;
    isContain?: boolean;
}

const CardPicture = ({ image, imageMobile, isContain }: CardPictureProps) => {
    const isMobile = useIsMobile();

    return (
        <Picture
            image={isMobile && imageMobile ? imageMobile : image}
            objectFit={isContain ? 'contain' : 'cover'}
            layout="fluid"
        />
    );
};

export default CardPicture;
