import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';

import styles from './styles.module.scss';
import BlogAuthor, { BlogAuthorProps } from '../Author';
import BlogWritedBy, { BlogWritedByProps } from '../WritedBy';
import BlogShare, { BlogShareProps } from '../Share';
import Topics, { Topic } from '../Topics';

export interface BlogInfoPostProps {
    className?: string;
    date: BlogWritedByProps['date'];
    socialIcons: BlogShareProps['socialIcons'];
    label: BlogShareProps['label'];
    author: BlogAuthorProps[];
    tags: Topic[];
    post: {
        title: string;
        url: string;
    };
    successMessage?: string;
}

const BlogInfoPost = ({
    date,
    socialIcons,
    author,
    className,
    label,
    post,
    tags,
    successMessage,
    ...props
}: BlogInfoPostProps) => {
    return (
        <div className={cls('d-flex', 'flex-column', className)} {...props}>
            <div className={cls('d-flex', styles.divider)}>
                {author.map((item, i) => (
                    <BlogAuthor
                        key={i}
                        name={item.name}
                        image={item.image}
                        job={item.job}
                        className={cls(styles.author, 'mb-sm')}
                    />
                ))}
            </div>
            <div className={cls('d-flex', 'flex-column', styles.divider)}>
                <BlogWritedBy
                    className={cls(styles.date, 'pb-sm', 'pt-sm')}
                    date={date}
                />
                <Topics
                    topics={tags}
                    limit={0}
                    size="small"
                    className={cls('pb-sm')}
                />
            </div>

            <BlogShare
                className={cls(styles.share, 'mt-sm')}
                socialIcons={socialIcons}
                label={label}
                post={post}
                successMessage={successMessage}
            />
        </div>
    );
};

export default BlogInfoPost;
