import { cls } from '@solublestudio/soluto-design-system';
import React from 'react';

import Link from '../Link';

import styles from './styles.module.scss';

export interface AlternateLanguageProps {
    href: string;
    language: string;
    active: boolean;
}

export interface SelectorLanguageProps {
    alternateLanguages: AlternateLanguageProps[];
    isDesktop?: boolean;
}
const SelectorLanguage = ({
    alternateLanguages = [],
    isDesktop = false,
}: SelectorLanguageProps) => {
    return alternateLanguages?.length >= 1 ? (
        <div
            className={cls(
                styles.selectorLanguage,
                isDesktop && styles.selectorDesktop,
            )}>
            {alternateLanguages.map(({ href, active, language }) => {
                return (
                    <Link
                        font="b400"
                        label={language}
                        href={href}
                        key={`languageItem-${language}-${
                            isDesktop ? 'desktop' : 'mobile'
                        }`}
                        className={cls(
                            styles.languageItem,
                            active && styles.active,
                        )}>
                        {language}
                    </Link>
                );
            })}
        </div>
    ) : null;
};

export default SelectorLanguage;
