import React from 'react';

import { Section, Container } from '@solublestudio/soluto-design-system';

import SectionsTitle, {
    SectionsTitleProps,
} from '@ds/components/shared/SectionsTitle';

const SectionsTitleSection: React.FC<SectionsTitleProps> = ({ ...props }) => {
    return (
        <Section className="pb-mega">
            <Container>
                <SectionsTitle {...props} />
            </Container>
        </Section>
    );
};

export default SectionsTitleSection;
