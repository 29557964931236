import React, { forwardRef, useState } from 'react';

import {
    Input as CRVInput,
    Icon,
    cls,
} from '@solublestudio/soluto-design-system';

import type { InputProps } from '@solublestudio/soluto-design-system';

import styles from './styles.module.scss';

const Input = forwardRef<HTMLInputElement, InputProps>(
    ({ iconName, rightIcon, classes, ...props }, ref) => {
        const [value, setValue] = useState(props.value ?? props.defaultValue);
        const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            if (typeof props.onChange === 'function') {
                props.onChange(e);
            }

            setValue(e.target.value);
        };

        return (
            <CRVInput
                ref={ref}
                {...props}
                onChange={onChange}
                classes={{
                    wrapper: cls(
                        iconName ? styles.inputContainer : null,
                        rightIcon ? styles.inputContainerRight : null,
                        props.disabled ? styles.iconDisabled : null,
                        classes?.wrapper,
                    ),
                    element: cls(
                        props.error ? styles.inputError : null,
                        props.disabled ? styles.inputDisabled : null,
                        styles.input,
                        classes?.input,
                    ),
                }}
                previousContent={
                    iconName ? (
                        <button
                            className={cls(styles.icon, styles.iconLeft)}
                            disabled={props.disabled}>
                            <Icon name={iconName} />
                        </button>
                    ) : undefined
                }
                laterContent={
                    rightIcon && value ? (
                        <button
                            className={cls(styles.icon, styles.iconRight)}
                            type="submit"
                            disabled={props.disabled}>
                            <Icon name={rightIcon} />
                        </button>
                    ) : undefined
                }
            />
        );
    },
);

export default Input;
