import React from 'react';
import {
    Container,
    Section,
    Row,
    Col,
    HTMLComponent,
    Text,
    cls,
    HubspotForm,
} from '@solublestudio/soluto-design-system';

import type { HTMLComponentProps } from '@solublestudio/soluto-design-system';
import Button, { CRVButtonProps as ButtonProps } from '../../../shared/Button';

import Picture from 'Picture';

import styles from './styles.module.scss';

export interface BlogContentProps extends Pick<HTMLComponentProps, 'text'> {
    title: string;
    content: any[];
    alignRight?: boolean;
    downloadButton?: ButtonProps;
}

const BlogContent: React.FC<BlogContentProps> = ({
    content,
    downloadButton,
    alignRight = true,
}) => {
    const renderContent = (c: any, i: number) => {
        return c.img?.file?.childImageSharp ? (
            <div key={`article-section-${i}`}>
                <Picture image={c.img} alt={c.img.alt} />
                {c.html ? (
                    <Text component="p" className="b100 text-base-000">
                        {c.html}
                    </Text>
                ) : null}
            </div>
        ) : c.form ? (
            <HubspotForm
                className={styles.formHubspot}
                key={`article-section-${i}`}
                portalId={c.form.portalId}
                formId={c.form.formId}
                region={c.form.region}
                scriptProps={{
                    defer: true,
                }}
            />
        ) : c.html ? (
            <HTMLComponent
                key={`article-section-${i}`}
                text={c.html}
                data-section={`article-section-${i}`}
                className={cls(styles.htmlSection)}
            />
        ) : null;
    };

    return (
        <Section className={cls('pt-huge pb-xxl')} component="article">
            <Container>
                <Row>
                    <Col offset={{ lg: alignRight ? 4 : 0 }} col={{ lg: 8 }}>
                        {content.length ? content.map(renderContent) : null}
                        {downloadButton ? (
                            <Button
                                {...downloadButton}
                                iconName="download"
                                variant="primary-small"
                                download
                            />
                        ) : null}
                    </Col>
                </Row>
            </Container>
        </Section>
    );
};

export default BlogContent;
