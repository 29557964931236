import React, { useState, useRef, useMemo, useEffect } from 'react';

import {
    Section,
    Row,
    Col,
    cls,
    Container,
    animateScroll,
} from '@solublestudio/soluto-design-system';

import Button, { CRVButtonProps } from '@ds/components/shared/Button';
import MediaBackgroundComponent, {
    MediaBackgroundComponentProps,
} from '@ds/components/shared/MediaBackgroundComponent';
import Text from '@ds/components/shared/Text';
import Heading from '@ds/components/shared/Heading';
import PerformantVideo from '@ds/components/shared/PerformantVideo';

import styles from './styles.module.scss';

export interface HeroSectionProps {
    title: string;
    background?: any;
    text?: string;
    ctas?: CRVButtonProps[];
    ctaDown?: CRVButtonProps;
    children?: React.ReactNode;
    align: 'end' | 'center';
    component?: React.ElementType;
    className?: string;
    withDelay?: boolean;
    bgCover?: MediaBackgroundComponentProps['bgCover'];
    theme?: string;
    withAnimation?: boolean;
    video?: MediaBackgroundComponentProps['background'];
}

export function scrollToNextItem() {
    if (typeof window === 'undefined') {
        return;
    }

    const node = document.getElementById('hero-section');

    if (node) {
        const { bottom } = node.getBoundingClientRect();
        const offset = window.pageYOffset;

        animateScroll(bottom, offset, 0.2);
    }
}

const HeroSection = ({
    title,
    background,
    text,
    ctas,
    ctaDown,
    children,
    align = 'center',
    component = 'section',
    className,
    withDelay = false,
    bgCover = true,
    theme = 'dark',
    withAnimation = false,
    video,
}: HeroSectionProps) => {
    const videoRef = useRef();

    const isVideoOnHover = useMemo(
        () => background?.isImage && !!video,
        [video, background],
    );

    const hoverEvent = useMemo(
        () =>
            typeof window !== 'undefined' && 'ontouchstart' in window
                ? 'onTouchStart'
                : 'onMouseEnter',
        [],
    );

    const [showVideo, setShowVideo] = useState(false);
    const [playVideo, setPlayVideo] = useState(false);

    useEffect(() => {
        if (playVideo && (videoRef.current as any)?.load) {
            (videoRef.current as any).load();
        }
    }, [playVideo, videoRef]);

    return (
        <Section
            {...(isVideoOnHover &&
                !playVideo && {
                    [hoverEvent]: () => {
                        setPlayVideo(true);
                    },
                })}
            id="hero-section"
            data-theme={theme}
            className={cls(
                'pt-xxxl pb-xxxl',
                styles.heroSection,
                bgCover && styles.bgCover,
                className,
            )}
            component={component}>
            {!!background && (
                <MediaBackgroundComponent
                    background={background}
                    withDelay={bgCover ? withDelay : false}
                    bgCover={bgCover}
                    withPriority
                    classes={{
                        wrapper: cls(!bgCover && 'd-none d-lg-block'),
                    }}
                />
            )}
            {isVideoOnHover && (
                <PerformantVideo
                    ref={videoRef}
                    className={cls(
                        styles.video,
                        showVideo && styles.videoShown,
                    )}
                    sources={[
                        {
                            src: video?.url,
                            type: video?.extension,
                        },
                    ]}
                    preload="none"
                    onCanPlay={(e) => {
                        if ((e.target as HTMLVideoElement).readyState === 4) {
                            (e.target as HTMLVideoElement).play();
                            setShowVideo(true);
                        }
                    }}
                />
            )}
            <Container className={`${styles.heroContainer} d-flex flex-column`}>
                <Row className={cls(styles.heroRow)}>
                    <Col
                        col={{ lg: bgCover ? 7 : 5 }}
                        className={cls(
                            `d-flex flex-column justify-content-${align}`,
                            styles.heroContent,
                        )}>
                        <Heading
                            {...(withAnimation && {
                                'data-sal': 'slide-up',
                                'data-sal-delay': '200',
                            })}
                            component="h1"
                            font={bgCover ? 'h500' : 'h400'}
                            className={cls(
                                styles.heroHeading,
                                bgCover && styles.heroHeadingBgCover,
                                'text-break',
                            )}>
                            {title}
                        </Heading>
                        {text && (
                            <Text
                                component="div"
                                font="h100"
                                {...(withAnimation && {
                                    'data-sal': 'slide-up',
                                    'data-sal-delay': '1200',
                                })}
                                className={cls('mt-xxl')}>
                                {text}
                            </Text>
                        )}
                        {ctas && (
                            <div
                                className={cls(
                                    'd-flex flex-column flex-lg-row mt-xxl',
                                )}>
                                {ctas.map((cta, index) => {
                                    return (
                                        <Button
                                            {...cta}
                                            key={index}
                                            variant="primary-large"
                                            iconName={
                                                index === 0 ? 'arrowRight' : ''
                                            }
                                            className={cls(
                                                styles.cta,
                                                'text-base-1000',
                                            )}
                                        />
                                    );
                                })}
                            </div>
                        )}
                    </Col>
                    {children && (
                        <Col
                            {...(withAnimation && {
                                'data-sal': 'slide-up',
                                'data-sal-delay': '1200',
                            })}
                            className="d-flex flex-column justify-content-center"
                            col={{ lg: 4 }}
                            offset={{ lg: 1 }}>
                            <div className={styles.heroChildren}>
                                {children}
                            </div>
                        </Col>
                    )}
                </Row>
                {ctaDown && (
                    <Row>
                        <Col
                            {...(withAnimation && {
                                'data-sal': 'slide-down',
                                'data-sal-delay': '2000',
                                'data-sal-duration': '300',
                            })}
                            className="d-flex align-items-center mt-md">
                            <Button
                                {...ctaDown}
                                component="button"
                                variant="hero-cta"
                                iconName="arrowDownCircle"
                                className="text-base-1000"
                                onClick={scrollToNextItem}
                                children={ctaDown?.label && ctaDown.label}
                            />
                        </Col>
                    </Row>
                )}
            </Container>
        </Section>
    );
};

export default HeroSection;
