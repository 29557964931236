import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';

import Link, { LinkProps } from 'Link';

import styles from './styles.module.scss';

export interface FooterNavigationMenuProps {
    links: LinkProps[];
}

const FooterNavigationMenu: React.FC<FooterNavigationMenuProps> = ({
    links,
}) => {
    return (
        <ul className={cls('list-unstyled', 'mb-none', 'b400')}>
            {links.map((link, i) => (
                <li key={i} className={cls('mt-lg', styles.item)}>
                    {link.href ? (
                        <Link {...link} className={cls(styles.link)}>
                            {link.label}
                        </Link>
                    ) : (
                        <span>{link.label}</span>
                    )}
                </li>
            ))}
        </ul>
    );
};

export default FooterNavigationMenu;
