import React from 'react';

import CardResource from '@ds/components/shared/Cards/Resource';

import GridListSection, { GridListSectionProps } from '../GridListSection';

export interface ResourceListPaginationProps extends GridListSectionProps {}

const ResourceListPagination: React.FC<ResourceListPaginationProps> = ({
    title,
    description,
    items,
    pagination,
}) => {
    return (
        <GridListSection
            title={title}
            description={description}
            items={items}
            pagination={pagination}
            ComponentCard={CardResource}
            itemsPerRow={3}
        />
    );
};

export default ResourceListPagination;
