import React from 'react';

import {
    Section,
    Row,
    Col,
    Container,
} from '@solublestudio/soluto-design-system';

import Heading from '@ds/components/shared/Heading';
import Button from '@ds/components/shared/Button';

import type { CRVButtonProps } from '@ds/components/shared/Button';

import styles from './styles.module.scss';
export interface PreFooterCtaProps {
    title: string;
    button?: CRVButtonProps;
}

const PreFooterCta = ({ title, button }: PreFooterCtaProps) => {
    return (
        <Section
            className="bg-primary pb-huge pt-huge mb-mega"
            data-theme="light">
            <Container>
                <Row>
                    <Col
                        col={{ xxl: 6 }}
                        data-sal="slide-right"
                        data-sal-delay="300">
                        <Heading component="h2" font="h300">
                            {title}
                        </Heading>
                        {button && (
                            <Button
                                {...button}
                                label={
                                    <div className={styles.buttonLabel}>
                                        <span>{button.label}</span>
                                    </div>
                                }
                                iconName="arrowRight"
                                variant="primary-large"
                                className="mt-xl"
                            />
                        )}
                    </Col>
                </Row>
            </Container>
        </Section>
    );
};

export default PreFooterCta;
