import React, { useCallback, useMemo } from 'react';

import { cls } from '@solublestudio/soluto-design-system';
import Heading from '@ds/components/shared/Heading';
import Text from '@ds/components/shared/Text';
import Picture, { PictureProps } from 'Picture';
import Link from 'Link';

import Topics, { TopicsProps } from '@ds/components/shared/Blog/Topics';

import type { LinkProps } from 'Link';
import { withSolutoPrefix } from '@solublestudio/soluto-design-system/src/utils/link';

import { navigate } from 'Link';

import styles from './styles.module.scss';
export interface CardContentProps {
    image: PictureProps;
    date: string;
    time: string;
    title: string;
    small?: boolean;
    link: LinkProps;
    topics: TopicsProps['topics'];
    withBg?: boolean;
}

const CardContent = ({
    image,
    title,
    date,
    time,
    small,
    link,
    topics,
    withBg = true,
}: CardContentProps) => {
    const hasBlogInfo = useMemo(() => date || time, [date, time]);

    const onClick = useCallback(
        (ev: React.MouseEvent<HTMLDivElement>) => {
            ev?.preventDefault();

            if (link?.target === '_blank') {
                window.open(link?.href, '_blank');
                return;
            }

            navigate(withSolutoPrefix(link.href));
        },
        [link],
    );

    return (
        <div
            {...(!!link?.href && {
                onClick,
                role: 'button',
            })}
            className={cls(styles.cardContent, small && styles.cardSmall)}>
            <Picture
                image={image}
                layout="full"
                alt={image?.alt ?? undefined}
                objectFit="cover"
                classes={{
                    wrapper: cls(styles.img),
                }}
            />
            <div
                className={cls(
                    styles.cardContentInfo,
                    withBg && styles.cardContentInfoBg,
                    small ? 'pt-lg' : 'py-lg px-md',
                    'd-flex',
                    'flex-column',
                )}>
                {hasBlogInfo && (
                    <div className={cls('mb-xxs')}>
                        <Text
                            component="span"
                            font="b200"
                            className={cls('mr-md', 'text-capitalize')}>
                            {date}
                        </Text>
                        <Text component="span" font="b200">
                            {time}
                        </Text>
                    </div>
                )}
                <Link {...link}>
                    <Heading
                        component="h4"
                        font="h100"
                        className={cls(styles.title)}>
                        {title}
                    </Heading>
                </Link>
                {!!topics?.length && (
                    <div className={cls(!small ? 'mt-auto' : 'mt-xxs')}>
                        <Topics
                            topics={topics}
                            size={small ? 'small' : undefined}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default CardContent;
