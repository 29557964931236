import React from 'react';

import { Row, Col, cls } from '@solublestudio/soluto-design-system';

import Heading from '@ds/components/shared/Heading';

import styles from './styles.module.scss';

export interface TitleValueBaseProps {
    className?: string;
    title?: string;
    children?: React.ReactNode;
}

const TitleValueBase: React.FC<TitleValueBaseProps> = ({
    className,
    title,
    children,
    ...props
}) => {
    return (
        <Row className={cls(className)} {...props}>
            <Col col={{ lg: 5 }}>
                {title && (
                    <Heading
                        component="h4"
                        font="h300"
                        className={cls(styles.title, 'mb-md')}>
                        {title}
                    </Heading>
                )}
            </Col>
            {children && (
                <Col col={{ lg: 6 }} offset={{ lg: 1 }}>
                    {children}
                </Col>
            )}
        </Row>
    );
};

export default TitleValueBase;
