import React, { useRef, useCallback, useEffect } from 'react';
import YouTube from 'react-youtube';
import { Modal, ModalRef, Icon } from '@solublestudio/soluto-design-system';
import styles from './styles.module.scss';

interface ModalVideoProps {
    isOpen: boolean;
    videoId: string | null;
    onClose: () => void;
}

const ModalVideo: React.FC<ModalVideoProps> = ({
    videoId,
    isOpen,
    onClose,
}) => {
    const modalRef = useRef<ModalRef>(null);
    const player = document.getElementById('youtube-player') as any;

    const closeModal = useCallback(() => {
        if (player) {
            player.pauseVideo();
        }
        if (modalRef.current) {
            modalRef.current.toggle();
        }
        onClose();
    }, [onClose, player, modalRef]);

    useEffect(() => {
        if (typeof document === 'undefined' || !isOpen) return;

        if (modalRef.current) {
            modalRef.current.toggle();
        }

        function onPressEscape(ev: any) {
            ev?.preventDefault();
            if (ev.key === 'Escape') {
                closeModal();
            }
        }
        document.addEventListener('keydown', onPressEscape);

        return () => {
            if (typeof document === 'undefined' || !isOpen) return;
            document.removeEventListener('keydown', onPressEscape);
        };
    }, [isOpen, closeModal]);

    return (
        <Modal
            ref={modalRef}
            classes={{
                content: styles.modalContent,
                dialog: styles.modalDialog,
            }}>
            <div className={styles.videoWrapper}>
                <button
                    className={styles.closeVideoButton}
                    onClick={closeModal}>
                    <Icon name="close" className={styles.closeIcon} />
                </button>
                {videoId && (
                    <YouTube
                        loading="lazy"
                        videoId={videoId}
                        className={styles.youtubeRoot}
                        iframeClassName={styles.youtubeIframe}
                        onEnd={closeModal}
                        opts={{
                            playerVars: { autoplay: 1, rel: 0 },
                        }}
                    />
                )}
            </div>
        </Modal>
    );
};

export default ModalVideo;
