import React, { useState } from 'react';
import useIsMobile from '@ds/hooks/useIsMobile';

import { Icon, cls } from '@solublestudio/soluto-design-system';
import Heading from '@ds/components/shared/Heading';
import Text from '@ds/components/shared/Text';

import styles from './styles.module.scss';

export interface CardMoreInfoProps {
    title: string;
    description: string;
}

const CardMoreInfo: React.FC<CardMoreInfoProps> = ({ title, description }) => {
    const [hoverEffect, setHoverEffect] = useState(false);
    const isMobile = useIsMobile();

    const onClickCard = () => {
        setHoverEffect((prevHover) => !prevHover);
    };

    return (
        <div
            className={cls(
                styles.card,
                'bg-base-1000',
                'text-base-000',
                'p-lg',
                'position-relative',
                isMobile && hoverEffect ? styles['active-card-mobile'] : '',
                !isMobile && styles['active-card'],
            )}
            onClick={onClickCard}>
            <div
                className={cls(
                    styles.wrapper,
                    'd-flex',
                    'align-items-end',
                    'position-relative',
                )}>
                <div className={cls(styles.content, 'mr-xxs')}>
                    <Heading component="h3" font="h300">
                        {title}
                    </Heading>
                    <Text
                        component="div"
                        font="b200"
                        className={cls(styles.description, 'mt-lg')}>
                        {description}
                    </Text>
                </div>
                <Icon
                    name={'chevronDown'}
                    size={40}
                    classes={{
                        icon: cls(styles.icon),
                    }}
                />
            </div>
        </div>
    );
};

export default CardMoreInfo;
