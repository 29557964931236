import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';
import Heading from '@ds/components/shared/Heading';
import Picture, { PictureProps } from 'Picture';

import styles from './styles.module.scss';

export interface CardNoGutterProps {
    title: string;
    image: PictureProps;
}

const CardNoGutter = ({ title, image }: CardNoGutterProps) => {
    return (
        <div
            data-theme="light"
            className={cls(
                styles['card'],
                !image && styles['noImage'],
                'bg-base-400',
                'd-flex',
                'flex-column',
                'justify-content-between',
            )}>
            <Heading className={cls('p-lg')} font="h100" component="h4">
                {title}
            </Heading>
            {image && (
                <Picture
                    alt={title}
                    image={image}
                    layout="full"
                    objectFit="cover"
                    classes={{
                        wrapper: cls(styles['picture']),
                    }}
                />
            )}
        </div>
    );
};

export default CardNoGutter;
