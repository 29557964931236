import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';
import Picture, { PictureProps } from 'Picture';
import LinksList from '@ds/components/shared/LinksList';
import type { CRVButtonProps } from '@ds/components/shared/Button';

export interface CardDownloadProps {
    image: PictureProps;
    smallSize?: boolean;
    linksItems: CRVButtonProps[];
}

const CardDownload = ({ image, smallSize, linksItems }: CardDownloadProps) => {
    return (
        <div>
            <Picture
                image={image}
                alt={image?.alt ?? undefined}
                layout="full"
                objectFit="cover"
                aspectRatio={smallSize ? 0.7363 : 0.5828}
                classes={{
                    wrapper: cls('mb-lg'),
                }}
            />
            <LinksList links={linksItems} />
        </div>
    );
};

export default CardDownload;
