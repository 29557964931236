// Create react context to manage the modal state

import ModalVideo from '@ds/components/organisms/ModalVideo';
import React, { useState, useCallback } from 'react';

const ModalContext = React.createContext({
    openModal: (videoId: string) => {},
    closeModal: () => {},
    isModalOpen: false,
});

const ModalProvider = ({ children }: { children: React.ReactNode }) => {
    const [selectedVideoId, setSelectedVideoId] = useState<string | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = (videoId: string) => {
        setSelectedVideoId(videoId);
        setIsModalOpen(true);
    };

    const closeModal = useCallback(() => {
        setSelectedVideoId(null);
        setIsModalOpen(false);
    }, []);

    return (
        <ModalContext.Provider value={{ openModal, closeModal, isModalOpen }}>
            {children}
            <ModalVideo
                videoId={selectedVideoId}
                isOpen={isModalOpen}
                onClose={closeModal}
            />
        </ModalContext.Provider>
    );
};

const ModalConsumer = ModalContext.Consumer;

function useModal() {
    const context = React.useContext(ModalContext);

    if (context === undefined) {
        throw new Error('useModal must be used within a ModalProvider');
    }

    return context;
}

export { ModalConsumer, ModalProvider, useModal };
