import React, { useMemo, useCallback } from 'react';

import { cls } from '@solublestudio/soluto-design-system';
import Button, { CRVButtonProps } from '@ds/components/shared/Button';

import styles from './styles.module.scss';

export interface LinksListProps {
    links: CRVButtonProps[];
    className?: string;
    showShare?: boolean;
    separed?: boolean;
}

const LinksList: React.FC<LinksListProps> = ({
    links,
    showShare,
    className,
    separed,
    ...props
}) => {
    const hasShare = useMemo(() => {
        return (
            showShare &&
            typeof window !== 'undefined' &&
            !!window.navigator?.share
        );
    }, [showShare]);

    const share = useCallback((title, url) => {
        window.navigator
            .share({
                title,
                url,
            })
            .catch(() => {});
    }, []);

    return (
        <ul className={cls('list-unstyled', 'mb-none', className)} {...props}>
            {links.map((button, i) => (
                <li
                    key={i}
                    className={cls(
                        links.length === i + 1
                            ? ''
                            : separed
                            ? 'mb-md'
                            : 'mb-xs',
                        hasShare &&
                            'd-flex align-items-center justify-content-between',
                    )}>
                    <Button
                        {...button}
                        iconName="download"
                        variant="primary-small"
                        download
                    />
                    {hasShare && (
                        <Button
                            className={styles.shareBtn}
                            variant="iconButtonSmall"
                            iconName="share"
                            component="button"
                            onClick={() => {
                                share(button.children, button.href);
                            }}
                        />
                    )}
                </li>
            ))}
        </ul>
    );
};

export default LinksList;
