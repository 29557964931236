module.exports = [{
      plugin: require('../../../node_modules/@solublestudio/soluto-design-system/gatsby-browser.js'),
      options: {"plugins":[],"variablesPath":"D:\\a\\1\\s\\packages\\design-system/src/scss/variables.scss","components":["Accordion","Breadcrumb","Button","Card","Col","Collapse","Container","GridBox","GridContainer","GridWrapper","HTMLComponent","Header","Heading","HubspotForm","Icon","Image","Input","Layout","LegacyHeader","Link","Modal","Pagination","Row","Section","Slider","SocialIcons","Table","Text","Video","VideoInline"],"alias":{"~icons":"D:\\a\\1\\s\\packages\\design-system\\icons.js","Picture":"D:\\a\\1\\s\\node_modules\\@solublestudio\\soluto-design-system\\src\\components\\Gatsby\\Picture","~logo":"D:\\a\\1\\s\\packages\\design-system\\src\\img\\logo-black.svg?url","~logo-white":"D:\\a\\1\\s\\packages\\design-system\\src\\img\\logo-white.svg?url","@ds/components":"D:\\a\\1\\s\\packages\\design-system\\src\\components","@ds/hooks":"D:\\a\\1\\s\\packages\\design-system\\src\\hooks","@ds/utils":"D:\\a\\1\\s\\packages\\design-system\\src\\utils","@ds/scss":"D:\\a\\1\\s\\packages\\design-system\\src\\scss","@ds/types":"D:\\a\\1\\s\\packages\\design-system\\src\\types","@ds/img":"D:\\a\\1\\s\\packages\\design-system\\src\\img"},"dirname":"D:\\a\\1\\s\\packages\\design-system","sal":true},
    },{
      plugin: require('../node_modules/@solublestudio/gatsby-theme-soluble-source/gatsby-browser.js'),
      options: {"plugins":[],"local":false,"primaryLanguage":"es","language":{"es":"es","en":"en"},"sitemap":true,"templateExtension":"tsx","datocms":{"cdnHost":"https://cuervaenergia.com/media","mediaPath":"60640","previewMode":false,"modelPages":["Page"],"redirects":false,"localesToGenerate":["es","en"]},"hubspotBlog":{"previewMode":false,"blogPostsParams":{"contentGroupIds":["46634696676","49989382371","57254850023"]},"paginationPerTopic":{"firstPageItems":16,"perPage":16},"templateKey":{"HubspotBlogPost":"blog-post","HubspotBlogTopic":"blog-topic"},"strategy":"domain_and_topic"},"slices":{"header":"D:\\a\\1\\s\\apps\\website\\src\\components\\Header\\index.tsx","footer":"D:\\a\\1\\s\\apps\\website\\src\\components\\Footer\\index.tsx"},"imageFormats":["webp"]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Cuerva","short_name":"Cuerva","start_url":"/","background_color":"#130e1e","theme_color":"#130e1e","display":"minimal-ui","icon":"src/img/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"acbe37eb6516b1fa866d953d658cf3e5"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
