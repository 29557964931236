import React from 'react';
import {
    Row,
    Col,
    Container,
    Section,
    cls,
} from '@solublestudio/soluto-design-system';

import ItemDescription from '@ds/components/shared/ItemDescription';
import type { ItemDescriptionProps } from '@ds/components/shared/ItemDescription';
import SectionsTitle, {
    SectionsTitleProps,
} from '@ds/components/shared/SectionsTitle';

import styles from './styles.module.scss';

export interface ItemsDescriptionSectionProps extends SectionsTitleProps {
    items: ItemDescriptionProps[];
    featured: boolean;
    reverse: boolean;
}

const ItemsDescriptionSection: React.FC<ItemsDescriptionSectionProps> = ({
    title,
    subtitle,
    featured = false,
    reverse = false,
    items = [],
}) => {
    return (
        <Section className={cls('pb-mega')}>
            <Container>
                {title || subtitle ? (
                    <SectionsTitle
                        title={title}
                        subtitle={subtitle}
                        className={cls(!subtitle && 'mb-xxxl')}
                    />
                ) : null}
                <Row className={cls(styles.row)} data-sal="slide-left-children">
                    {items.map((item, i) => (
                        <Col
                            data-sal="slide"
                            data-sal-delay={i * 200}
                            col={{
                                xs:
                                    !item.description &&
                                    !item.title &&
                                    item.image
                                        ? 6
                                        : 12,
                                lg:
                                    item.image || reverse
                                        ? items.length === 3
                                            ? 4
                                            : 3
                                        : 4,
                            }}
                            key={`description-section-item-${i}`}>
                            <ItemDescription
                                {...item}
                                key={i}
                                featured={featured}
                                reverse={reverse}
                            />
                        </Col>
                    ))}
                </Row>
            </Container>
        </Section>
    );
};

export default ItemsDescriptionSection;
