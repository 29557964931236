import React, { forwardRef } from 'react';

import {
    Button as CRVButton,
    Icon,
    cls,
} from '@solublestudio/soluto-design-system';

import type { ButtonProps } from '@solublestudio/soluto-design-system';

import styles from './styles.module.scss';

export interface CRVButtonProps extends ButtonProps {
    variant:
        | 'hero-cta'
        | 'primary-large'
        | 'primary-small'
        | 'primary-small-block'
        | 'iconButtonSmall'
        | 'iconButton'
        | 'iconButtonBase';
    iconName?: string;
    className?: string;
    label?: string | React.ReactNode;
    file?: {
        url: string;
    };
}

const variants = {
    'hero-cta': 'b200',
    'primary-large': 'h100',
    'primary-small': 'b100',
    'primary-small-block': 'b100',
    iconButton: '',
    iconButtonSmall: '',
    iconButtonBase: '',
};

const Button = forwardRef<HTMLButtonElement, CRVButtonProps>(function Button(
    { className, iconName, variant, label, file, href, ...props },
    ref,
) {
    const finalHref = file?.url ? file.url : href;

    return (
        <CRVButton
            {...props}
            href={finalHref}
            variant={
                variant === 'iconButton' ||
                variant === 'iconButtonSmall' ||
                variant === 'hero-cta'
                    ? variant
                    : 'default'
            }
            ref={ref}
            className={cls(
                styles['button'],
                variants[variant],
                styles[variant],
                className,
            )}
            beforeContent={
                iconName ? (
                    <Icon
                        name={iconName}
                        size={
                            variant === 'primary-large'
                                ? '32'
                                : variant === 'hero-cta'
                                ? '40'
                                : variant === 'iconButtonSmall'
                                ? '16'
                                : '24'
                        }
                        classes={{
                            ...([
                                'primary-large',
                                'primary-small',
                                'hero-cta',
                            ].includes(variant) && {
                                icon: cls(
                                    variant === 'hero-cta'
                                        ? 'mr-xs'
                                        : 'mr-tiny',
                                ),
                            }),
                        }}
                    />
                ) : undefined
            }>
            {label}
        </CRVButton>
    );
});

export default Button;
