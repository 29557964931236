import React from 'react';

import {
    cls,
    Container,
    Icon,
    Row,
    Col,
} from '@solublestudio/soluto-design-system';

import FooterNavigationMenu, { FooterNavigationMenuProps } from './Menu';
import CarouselBaseSection from '@ds/components/organisms/CarouselBaseSection';

import useIsMobile from '@ds/hooks/useIsMobile';

import styles from './styles.module.scss';

export interface FooterNavigationProps {
    navs: FooterNavigationMenuProps[];
}

const FooterNavigation: React.FC<FooterNavigationProps> = ({
    navs,
    ...props
}) => {
    const isMobile = useIsMobile();

    return (
        <footer className={cls(styles.footer, 'pb-xxxl')} {...props}>
            <Container>
                <Icon
                    name={'cuervaAsterisco'}
                    classes={{
                        icon: cls(styles.logo, 'mb-xxxl'),
                    }}
                />
                {!isMobile && (
                    <Row>
                        {navs?.length
                            ? navs.map((item, i) => (
                                  <Col key={`col-${i}`} col={{ lg: 3 }}>
                                      <FooterNavigationMenu
                                          links={item.links}
                                          key={`footer-navigation-menu-${i}`}
                                      />
                                  </Col>
                              ))
                            : []}
                    </Row>
                )}
            </Container>
            {isMobile && (
                <CarouselBaseSection
                    classes={{
                        wrapper: cls(styles.carousel),
                    }}
                    items={
                        navs?.length
                            ? navs.map((item, i) => (
                                  <FooterNavigationMenu
                                      key={`menu-${i}`}
                                      links={item.links}
                                  />
                              ))
                            : []
                    }
                />
            )}
        </footer>
    );
};

export default FooterNavigation;
