import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';

import Heading from '@ds/components/shared/Heading';
import Text from '@ds/components/shared/Text';

import styles from './styles.module.scss';

export interface CardBeforeAndAfterProps {
    description: string;
    dontStyle?: boolean;
}

const CardBeforeAndAfter = ({
    description,
    dontStyle,
}: CardBeforeAndAfterProps) => {
    return (
        <div
            data-theme="light"
            className={cls(
                styles.card,
                dontStyle ? 'bg-base-400' : 'bg-dont',
                'd-flex',
                'flex-column',
                'py-md',
                'pl-md',
                'pr-xxl',
            )}>
            <Heading className={cls('mb-sm')} font="b300" component="h3">
                {dontStyle ? 'Después' : 'Antes'}
            </Heading>
            <Text component="div" font="h100" className={styles.description}>
                {description}
            </Text>
        </div>
    );
};

export default CardBeforeAndAfter;
