import React from 'react';

import {
    Section,
    Container,
    Row,
    Col,
} from '@solublestudio/soluto-design-system';

import Heading from '@ds/components/shared/Heading';
import FaqsComponent, {
    FaqComponentProps,
} from '@ds/components/shared/FaqsComponent';

export interface FaqsSectionProps {
    title: string;
    faqs: FaqComponentProps[];
    textShow: string;
    textHidden: string;
}

export default function FaqsSection({
    title,
    faqs,
    textShow,
    textHidden,
}: FaqsSectionProps) {
    return (
        <Section className="pb-mega">
            <Container>
                <Row>
                    <Col
                        col={{ xs: 12 }}
                        data-sal="slide-up"
                        data-sal-delay="300">
                        <Heading component="h2" font="h300" className="mb-xxl">
                            {title}
                        </Heading>
                        <FaqsComponent
                            items={faqs}
                            textShow={textShow}
                            textHidden={textHidden}
                        />
                    </Col>
                </Row>
            </Container>
        </Section>
    );
}
