import React, { useCallback } from 'react';

import {
    Section,
    cls,
    Container,
    Row,
    Col,
} from '@solublestudio/soluto-design-system';

import Heading from '@ds/components/shared/Heading';
import Text from '@ds/components/shared/Text';

export interface HeroIntroSectionProps {
    title: string;
    text?: string;
    withOutAnimation?: boolean;
    size?: 'small' | 'big';
}

const HeroIntroSection = ({
    title,
    text,
    withOutAnimation = false,
    size = 'big',
}: HeroIntroSectionProps) => {
    const hasText = !!text;

    const renderSubtitle = useCallback(
        (text: string) => {
            const commonProps = {
                ...(!withOutAnimation && {
                    'data-sal': 'slide-up',
                    'data-sal-delay': '400',
                }),
            };

            if (size === 'small') {
                return (
                    <Text
                        component={'div'}
                        font="b400"
                        className="pt-sm"
                        {...commonProps}>
                        {text}
                    </Text>
                );
            }

            return (
                <Heading
                    component={'h2'}
                    font="h300"
                    className={'mb-xxxl'}
                    {...commonProps}>
                    {text}
                </Heading>
            );
        },
        [size, withOutAnimation],
    );

    return (
        <Section
            id="hero-intro-section"
            className={cls('pt-mega', {
                'pb-xxxl': hasText,
                'pb-mega': !hasText,
            })}>
            <Container>
                <Row>
                    <Col col={{ lg: 8 }}>
                        <Heading
                            {...(!withOutAnimation && {
                                'data-sal': 'slide-up',
                                'data-sal-delay': '200',
                            })}
                            component="h1"
                            font={'h500'}
                            className={cls({
                                'mb-mega': hasText && size === 'big',
                            })}>
                            {title}
                        </Heading>
                    </Col>
                    {hasText && (
                        <Col col={{ lg: 8 }}>{renderSubtitle(text)}</Col>
                    )}
                </Row>
            </Container>
        </Section>
    );
};

export default HeroIntroSection;
