import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';

import TitleValueBase, { TitleValueBaseProps } from '..';

import Text from '@ds/components/shared/Text';
import Link from '@ds/components/shared/Link';

import type { LinkProps } from '@ds/components/shared/Link';

export interface TitleValueProps extends TitleValueBaseProps {
    description?: string;
    link?: LinkProps;
}

const TitleValue: React.FC<TitleValueProps> = ({
    description,
    link,
    ...props
}) => {
    return (
        <TitleValueBase {...props}>
            <Text component="div" font="b300">
                {description}
            </Text>
            {link && (
                <Link
                    {...link}
                    font="b300"
                    className={cls('d-inline-block', 'mt-tiny')}>
                    {link.label}
                </Link>
            )}
        </TitleValueBase>
    );
};

export default TitleValue;
