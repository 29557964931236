import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';
import Button, { CRVButtonProps } from '@ds/components/shared/Button';

import styles from './styles.module.scss';

export interface CardMediaBaseProps {
    className?: string;
    smallSize?: boolean;
    button: CRVButtonProps;
    children?: React.ReactNode;
}

const CardMediaBase = ({
    className,
    smallSize,
    button,
    children,
}: CardMediaBaseProps) => {
    return (
        <div>
            <div
                className={cls(
                    'position-relative',
                    styles[smallSize ? 'smallCard' : 'card'],
                    'bg-base-400',
                    className,
                )}>
                {children}
            </div>
            <Button
                {...button}
                className={cls('mt-md')}
                iconName="chevronCircleRight"
                variant="primary-small"
            />
        </div>
    );
};

export default CardMediaBase;
