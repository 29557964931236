import React from 'react';
import { Container, Section, cls } from '@solublestudio/soluto-design-system';

import TitleValueList from '@ds/components/shared/TitleValueBase/List';
import type { TitleValueListProps } from '@ds/components/shared/TitleValueBase/List';

import SectionsTitle, {
    SectionsTitleProps,
} from '@ds/components/shared/SectionsTitle';

import styles from '../styles.module.scss';

export interface TitleValueListSectionProps
    extends Omit<SectionsTitleProps, 'component' | 'className' | 'link'> {
    listItems: TitleValueListProps[];
}

const TitleValueListSection: React.FC<TitleValueListSectionProps> = ({
    title,
    subtitle,
    listItems,
}) => {
    return (
        <Section className={cls('pb-mega')}>
            <Container>
                {title || subtitle ? (
                    <SectionsTitle
                        title={title}
                        subtitle={subtitle}
                        className={subtitle ? '' : 'mb-xxxl'}
                    />
                ) : null}
                {listItems.map((item, i) => {
                    return (
                        <TitleValueList
                            {...item}
                            key={i}
                            className={cls(
                                styles.item,
                                listItems[i + 1] && 'pb-xxl',
                                listItems[i - 1] && 'pt-xxl',
                            )}
                            data-sal="slide-up"
                            data-sal-delay={i * 200}
                        />
                    );
                })}
            </Container>
        </Section>
    );
};

export default TitleValueListSection;
