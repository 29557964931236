import React from 'react';
import { cls } from '@solublestudio/soluto-design-system';

import CarouselBaseSection from '@ds/components/organisms/CarouselBaseSection';
import PostContent, {
    PostContentProps,
} from '@ds/components/shared/PostContent';

import SectionsTitle, {
    SectionsTitleProps,
} from '@ds/components/shared/SectionsTitle';
import DynamicCarouselCard from './DynamicCarouselCard';

import type { Component } from './DynamicCarouselCard';

import styles from './styles.module.scss';

export interface CarouselCardsSectionProps
    extends SectionsTitleProps,
        PostContentProps {
    size?: 'large' | 'medium' | 'small' | 'double' | 'triple' | 'quadruple';
    items: Component[];
    className?: string;
    sliderCardsControls?: boolean;
    anchor?: string;
}

const maxItemsBySize: {
    [key in CarouselCardsSectionProps['size'] as string]: number;
} = {
    large: 3,
    medium: 3,
    small: 3,
    double: 2,
    triple: 3,
    quadruple: 4,
};

const CarouselCardsSection: React.FC<CarouselCardsSectionProps> = ({
    title,
    subtitle,
    size = 'large',
    anchor,
    items,
    textLeft,
    textRight,
    className,
    link,
    subtitleCols = 9,
    sliderCardsControls = true,
}) => {
    return (
        <CarouselBaseSection
            sliderCardsControls={
                sliderCardsControls && items?.length > maxItemsBySize?.[size]
            }
            classes={{
                section: cls('pb-mega', className),
                wrapper: cls(styles.wrapper, styles[`${size}`]),
            }}
            items={
                items?.length
                    ? items.map((item, i) => (
                          <DynamicCarouselCard {...item} i={i} />
                      ))
                    : []
            }
            preContent={
                title || subtitle ? (
                    <SectionsTitle
                        title={title}
                        subtitle={subtitle}
                        link={link}
                        subtitleCols={subtitleCols}
                        className={subtitle ? '' : 'mb-xxxl'}
                    />
                ) : null
            }
            postContent={
                (textLeft || textRight) && (
                    <PostContent
                        textLeft={textLeft}
                        textRight={textRight}
                        className={'mt-xl'}
                    />
                )
            }
            anchor={anchor}
        />
    );
};

export default CarouselCardsSection;
