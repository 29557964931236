import React from 'react';

import {
    Section,
    cls,
    Container,
    Row,
    Col,
} from '@solublestudio/soluto-design-system';

import HeroIntroSection, {
    HeroIntroSectionProps,
} from '@ds/components/organisms/HeroIntroSection';

import BlogInfoPost, {
    BlogInfoPostProps,
} from '@ds/components/shared/Blog/InfoPost';

import Picture from 'Picture';

import styles from './styles.module.scss';

export interface BlogIntroSectionProps {
    title: HeroIntroSectionProps['title'];
    infoPost: BlogInfoPostProps;
    image: any;
    successMessage?: string;
}

const BlogIntroSection = ({
    title,
    infoPost,
    image,
    successMessage,
}: BlogIntroSectionProps) => {
    return (
        <>
            <HeroIntroSection title={title} withOutAnimation />
            <Section>
                <Container>
                    <Row>
                        <Col col={{ lg: 8 }} order={{ lg: 2 }}>
                            <Picture
                                image={image}
                                alt={image?.alt ?? undefined}
                                loading="eager"
                                layout="full"
                                objectFit="cover"
                                aspectRatio={0.7146}
                                classes={{
                                    wrapper: cls(styles.imgWrapper, 'mb-xxl'),
                                }}
                            />
                        </Col>
                        <Col col={{ lg: 4 }} order={{ lg: 1 }}>
                            <BlogInfoPost
                                date={infoPost.date}
                                socialIcons={infoPost.socialIcons}
                                label={infoPost.label}
                                author={infoPost.author}
                                tags={infoPost.tags}
                                post={infoPost.post}
                                successMessage={successMessage}
                            />
                        </Col>
                    </Row>
                </Container>
            </Section>
        </>
    );
};

export default BlogIntroSection;
