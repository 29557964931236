import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';
import Text from '@ds/components/shared/Text';
import Picture, { PictureProps } from 'Picture';

import SocialIcons from '@ds/components/shared/SocialIcons';

import styles from './styles.module.scss';

export interface BlogAuthorProps {
    className?: string;
    name: string;
    job?: string;
    linkedin?: string;
    description?: string;
    image?: PictureProps;
    sizeLarge?: boolean;
    flexColumns?: boolean;
}

const NoAvatar = ({ name }: BlogAuthorProps) => {
    const firstLetterAvatar = name.charAt(0);

    return (
        <Text
            component="div"
            font="h100"
            className={cls(
                styles.avatar,
                'd-flex',
                'align-items-center',
                'justify-content-center',
                'bg-base-400',
            )}>
            {firstLetterAvatar}
        </Text>
    );
};

const BlogAuthor = ({
    className,
    name,
    description,
    image,
    sizeLarge,
    flexColumns,
    job = '',
    linkedin,
    ...props
}: BlogAuthorProps) => {
    const social =
        [{ name: 'linkedin', url: linkedin }].filter((el) => el?.url) ?? [];

    return (
        <div
            className={cls(
                'd-flex',
                'align-items-lg-start',
                !flexColumns && 'flex-lg-column',
                flexColumns ? 'align-items-start' : 'align-items-center',
                className,
            )}
            {...props}>
            {image ? (
                <Picture
                    classes={{
                        wrapper: cls(
                            styles.avatar,
                            flexColumns && styles.avatarFlex,
                            sizeLarge && styles.large,
                        ),
                    }}
                    image={image}
                    alt={image?.alt ?? undefined}
                    layout="full"
                    objectFit="cover"
                />
            ) : (
                <NoAvatar name={name} />
            )}
            <div>
                <Text
                    className={cls(
                        styles.name,
                        description && 'font-weight-bold',
                    )}
                    font="b100">
                    {job ? `${name}, ${job}` : name}
                </Text>
                {description && (
                    <Text component="div" font="b100">
                        {description}
                    </Text>
                )}
                {!!social?.length && (
                    <SocialIcons
                        icons={social}
                        size={24}
                        classes={{
                            wrapper: 'mt-xs',
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default BlogAuthor;
