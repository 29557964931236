import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';
import Text from '@ds/components/shared/Text';

export interface BlogWritedByProps {
    className?: string;
    date: string;
}

const BlogWritedBy = ({ className, date }: BlogWritedByProps) => {
    return (
        <Text className={cls(className)} component="span" font="b100">
            {date}
        </Text>
    );
};

export default BlogWritedBy;
