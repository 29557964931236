import React from 'react';

import {
    Section,
    Row,
    Col,
    cls,
    Container,
} from '@solublestudio/soluto-design-system';

import Heading from '@ds/components/shared/Heading';

import BlogAuthor, { BlogAuthorProps } from '@ds/components/shared/Blog/Author';

import styles from './styles.module.scss';

export interface BlogAboutAuthorSectionProps {
    title: string;
    author: BlogAuthorProps[];
}

const BlogAboutAuthorSection = ({
    title,
    author = [],
}: BlogAboutAuthorSectionProps) => {
    return (
        <Section className={cls('mb-huge')}>
            <Container>
                <Row className={cls(styles.wrapper, 'pt-huge', 'pb-huge')}>
                    <Col col={{ lg: 5, xxl: 4 }}>
                        <Heading
                            component="h2"
                            font="h100"
                            className={cls('mb-xxl')}>
                            {title}
                        </Heading>
                    </Col>
                    {author.map((item, i) => (
                        <Col
                            key={`author-${i}`}
                            className={cls('mb-sm')}
                            col={{ lg: 7, xxl: 4 }}
                            offset={{
                                lg: i > 0 && 5,
                                xxl: i && i % 2 === 0 ? 4 : 0,
                            }}>
                            <BlogAuthor {...item} sizeLarge flexColumns />
                        </Col>
                    ))}
                </Row>
            </Container>
        </Section>
    );
};

export default BlogAboutAuthorSection;
