import React from 'react';

import CardContent from '@ds/components/shared/Cards/Content';

import GridListSection, { GridListSectionProps } from '../../GridListSection';

export interface BlogListPaginationProps extends GridListSectionProps {}

const BlogListPagination: React.FC<BlogListPaginationProps> = ({
    title,
    description,
    items,
    pagination = {
        pages: 0,
        pagesDepth: 2,
        activePage: 1,
        itemsPerPage: 10,
    },
}) => {
    return (
        <GridListSection
            title={title}
            description={description}
            items={items}
            pagination={pagination}
            ComponentCard={(item) => <CardContent {...item} withBg={false} />}
            itemsPerRow={4}
        />
    );
};

export default BlogListPagination;
