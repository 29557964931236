import React, { useState } from 'react';

import { Accordion } from '@solublestudio/soluto-design-system';
import Heading from '@ds/components/shared/Heading';
import Text from '@ds/components/shared/Text';

import styles from './styles.module.scss';
export interface FaqComponentProps {
    title: string;
    body: string;
    show: boolean;
}
export interface FaqsComponentProps {
    items: FaqComponentProps[];
    textShow: string;
    textHidden: string;
}

export default function FaqsComponent({
    items,
    textShow,
    textHidden,
}: FaqsComponentProps) {
    const [activeItem, setActiveItem] = useState<number | null>();

    return (
        <Accordion
            onOpenItemChange={(e) => setActiveItem(e)}
            initialShow={-1}
            items={items.map((item, index) => ({
                ...item,
                title: (
                    <>
                        <Heading
                            component="h3"
                            font="b400"
                            className={styles.itemTitleText}>
                            {item.title}
                        </Heading>
                        <Text
                            component="span"
                            font="b100"
                            className={styles.itemButton}>
                            {index === activeItem ? textHidden : textShow}
                        </Text>
                    </>
                ),
                body: item.body,
            }))}
            classes={{
                wrapper: styles.accordion,
                item: `py-md ${styles.item}`,
                title: `d-lg-flex justify-content-lg-between align-items-lg-center ${styles.itemTitle}`,
                body: `${styles.itemBody} b300`,
            }}
        />
    );
}
