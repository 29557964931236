import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';

import TitleValueBase, { TitleValueBaseProps } from '../';
import Heading from '@ds/components/shared/Heading';
import Text from '@ds/components/shared/Text';
import Link from '@ds/components/shared/Link';

import type { LinkProps } from '@ds/components/shared/Link';

import styles from './styles.module.scss';

interface ListItem {
    subtitle?: string;
    description?: string;
    anotation?: string;
    link?: LinkProps;
}

export interface TitleValueListProps extends TitleValueBaseProps {
    title?: TitleValueBaseProps['title'];
    listItems: ListItem[];
}

const TitleValueList: React.FC<TitleValueListProps> = ({
    title,
    listItems = [],
    ...props
}) => {
    return (
        <TitleValueBase title={title} {...props}>
            {listItems?.length > 0 &&
                listItems.map((item, i) => (
                    <div
                        key={i}
                        className={cls(
                            styles.itemWrapper,
                            'position-relative',
                        )}>
                        {item.subtitle && (
                            <Heading
                                component="h5"
                                font="h200"
                                className={cls(
                                    item.link ? 'mb-tiny' : 'mb-lg',
                                )}>
                                {item.subtitle}
                            </Heading>
                        )}
                        {item.description && (
                            <Text
                                component="div"
                                font="b300"
                                className={cls('mb-sm')}>
                                {item.description}
                            </Text>
                        )}
                        {item.anotation && (
                            <Text
                                component="div"
                                font="b300"
                                className={cls(styles.anotation)}>
                                {item.anotation}
                            </Text>
                        )}
                        {item.link && (
                            <Link
                                {...item.link}
                                font="b300"
                                className={cls('d-inline-block', 'mt-tiny')}>
                                {item.link.label}
                            </Link>
                        )}
                    </div>
                ))}
        </TitleValueBase>
    );
};

export default TitleValueList;
