import React from 'react';
import { cls } from '@solublestudio/soluto-design-system';

import CarouselCardsSection from '@ds/components/organisms/CarouselCardsSection';

import type { CarouselCardsSectionProps } from '@ds/components/organisms/CarouselCardsSection';

import { LinkProps } from 'Link';

export interface ResourcesSectionProps
    extends Omit<CarouselCardsSectionProps, 'size'> {
    data: CarouselCardsSectionProps['items'];
    title: CarouselCardsSectionProps['title'];
    cta?: LinkProps;
    pb?: 'huge' | 'xxxl';
}

const ResourcesSection = ({
    title,
    data,
    cta,
    pb = 'huge',
    ...props
}: ResourcesSectionProps) => {
    return (
        <CarouselCardsSection
            {...props}
            {...(!!cta && { sliderCardsControls: false })}
            className={cls(`pb-${pb}`)}
            items={data}
            subtitle={title}
            link={cta}
            size="triple"
            subtitleCols={12}
        />
    );
};

export default ResourcesSection;
