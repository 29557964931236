import React, { useRef, useState } from 'react';

import { cls } from '@solublestudio/soluto-design-system';
import Text from '@ds/components/shared/Text';
import Button from '@ds/components/shared/Button';
import { ColorThemeProps } from '..';

import styles from './styles.module.scss';

export interface CopyToClipboardProps extends ColorThemeProps {
    value: string;
    btnClassName?: string;
    copyValueLabel?: string;
    successMessage?: string;
}

const CopyToClipboard = ({
    value,
    btnClassName,
    copyValueLabel = 'Copiar valor',
    successMessage = 'Copiado al portapapeles',
    themeLight,
}: CopyToClipboardProps) => {
    const [copySuccess, setCopySuccess] = useState('');

    const jsonTextAreaRef = useRef<HTMLTextAreaElement>(null);

    const copyToClipboard = () => {
        jsonTextAreaRef.current?.select();

        document.execCommand('copy');

        setCopySuccess(successMessage);

        setTimeout(() => {
            setCopySuccess('');
        }, 3000);
    };

    return (
        <>
            <Button
                variant="primary-small"
                className={cls(styles.clipboardBtn, btnClassName)}
                onClick={copyToClipboard}
                title={copyValueLabel + ': ' + value}
                component="button"
                label={copyValueLabel}
                data-theme={themeLight && 'light'}
            />
            {copySuccess && (
                <Text
                    font="b100"
                    component="div"
                    className={cls(
                        styles.clipboardMessage,
                        themeLight
                            ? 'text-base-000 bg-base-1000'
                            : 'text-base-1000 bg-base-000',
                        'py-tiny',
                        'px-xxs',
                    )}>
                    {copySuccess}
                </Text>
            )}
            <textarea
                ref={jsonTextAreaRef}
                className={cls(styles.clipboardTextarea)}
                readOnly
                value={value}
            />
        </>
    );
};

export default CopyToClipboard;
