import React from 'react';

import {
    Section,
    cls,
    Container,
    Row,
    Col,
} from '@solublestudio/soluto-design-system';

import Heading from '@ds/components/shared/Heading';
import MediaBackgroundComponent, {
    MediaBackgroundComponentProps,
} from '@ds/components/shared/MediaBackgroundComponent';

import CardInfoPost, {
    CardInfoPostProps,
} from '@ds/components/shared/Blog/CardInfoPost';

import styles from './styles.module.scss';

export interface HeroPostSectionProps {
    title?: string;
    post: Omit<CardInfoPostProps, 'withCard'> & {
        background: MediaBackgroundComponentProps['background'];
    };
}

const HeroPostSection = ({ title, post }: HeroPostSectionProps) => {
    const dataTheme = 'dark';

    return (
        <Section className={styles.heroPostSection}>
            {title ? (
                <Container>
                    <Heading
                        component="h1"
                        font={'b400'}
                        className="pb-xxl text-center pt-mega">
                        {title}
                    </Heading>
                </Container>
            ) : null}
            {post ? (
                <div
                    className={cls(
                        'pb-xl mb-xxxl position-relative d-flex justify-content-end align-items-end',
                        styles.wrapperImage,
                    )}>
                    {post?.background && (
                        <MediaBackgroundComponent
                            bgCover
                            withDelay={false}
                            background={{ isImage: true, ...post?.background }}
                            withPriority
                        />
                    )}
                    <Container className={styles.content}>
                        <Row>
                            <Col col={{ lg: 8 }}>
                                <CardInfoPost
                                    category={post?.category}
                                    title={post?.title}
                                    topics={post?.topics}
                                    time={post?.time}
                                    dataTheme={dataTheme}
                                    slug={post?.slug}
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>
            ) : (
                <div className={cls('mb-xxxl')} />
            )}
        </Section>
    );
};

export default HeroPostSection;
