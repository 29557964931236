import React, { useMemo } from 'react';

import Link from '@ds/components/shared/Link';
import Text from '@ds/components/shared/Text';

import { cls } from '@solublestudio/soluto-design-system';

import styles from './styles.module.scss';

import { withSolutoPrefix } from '@solublestudio/soluto-design-system/src/utils/link';

import { navigate } from 'Link';

export type Topic = {
    name: string;
    slug?: string;
};

export interface TopicsProps {
    topics: Topic[];
    size?: 'small' | 'large';
    limit?: number;
    className?: string;
}

const Topics = ({
    topics,
    size = 'large',
    limit = 2,
    className,
}: TopicsProps) => {
    const font = size === 'small' ? 'b100' : 'b300';

    const topicsSliced = useMemo(
        () =>
            topics?.length > 0
                ? limit > 0
                    ? topics
                          .slice(0, limit)
                          .sort((a, b) => a?.name?.length - b?.name?.length)
                    : topics
                : [],
        [topics, limit],
    );

    const quantityRestTopics = useMemo(
        () => topics?.length - topicsSliced?.length,
        [topics?.length, topicsSliced?.length],
    );

    if (topicsSliced?.length > 0) {
        return (
            <div
                className={cls(
                    'd-flex',
                    'flex-wrap',
                    'align-items-center',
                    styles.topics,
                    className,
                )}>
                {topicsSliced.map((topic, i) => (
                    <Link
                        key={`topic-${i}`}
                        href={topic?.slug}
                        font={font}
                        onClick={(ev) => {
                            ev?.preventDefault();
                            ev?.stopPropagation();
                            navigate(withSolutoPrefix(topic?.slug as string));
                        }}
                        className={cls(
                            styles.link,
                            i !== topics?.length && 'mr-xxs mb-modulor',
                        )}>
                        {topic?.name}
                    </Link>
                ))}
                {quantityRestTopics > 0 ? (
                    <Text font={font} className={styles.restTopics}>
                        {`+${quantityRestTopics}`}
                    </Text>
                ) : null}
            </div>
        );
    }

    return null;
};

export default Topics;
