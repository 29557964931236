import React from 'react';

import {
    Row,
    Col,
    cls,
    Container,
    Text,
    Image,
} from '@solublestudio/soluto-design-system';

import Link, { LinkProps } from 'Link';
import SocialIcons from '@ds/components/shared/SocialIcons';
import type { SocialIconsDSProps } from '@ds/components/shared/SocialIcons';
import SimpleFooterLogo from '@ds/img/ue-union-europea.svg?url';

import styles from './styles.module.scss';

export interface FooterProps {
    copyright?: string;
    links: LinkProps[];
    socialIcons: SocialIconsDSProps;
    simpleFooter?: boolean;
}

const Footer: React.FC<FooterProps> = ({
    copyright,
    links,
    socialIcons,
    isBrand = true,
    simpleFooter = false,
}) => {
    return (
        <footer className={cls(styles.footer)}>
            <Container className={cls(isBrand ? 'py-xxl' : 'pb-xxl')}>
                <Row>
                    {copyright ? (
                        <Col
                            col={{
                                lg: simpleFooter ? 6 : 2,
                                xl: simpleFooter && 5,
                            }}
                            className={cls(
                                simpleFooter &&
                                    styles['simple-footer-copyright'],
                            )}>
                            {simpleFooter && SimpleFooterLogo && (
                                <Image
                                    src={SimpleFooterLogo}
                                    alt="Unión Europea"
                                    className={styles.image}
                                />
                            )}
                            <Text className="b-100">{copyright}</Text>
                        </Col>
                    ) : null}
                    <Col
                        col={{
                            lg: copyright ? (simpleFooter ? 6 : 7) : 8,
                            xl: simpleFooter && 7,
                        }}>
                        <ul
                            className={cls(
                                styles['list'],
                                'list-unstyled',
                                'b100',
                                simpleFooter && styles['align-end'],
                            )}>
                            {links.map((link, i) => (
                                <li key={i}>
                                    <Link {...link}>{link.label}</Link>
                                </li>
                            ))}
                        </ul>
                    </Col>
                    {socialIcons.length ? (
                        <Col
                            className={cls(
                                styles['social'],
                                'align-items-center',
                            )}
                            col={{ lg: 3 }}>
                            <SocialIcons
                                icons={socialIcons}
                                classes={{
                                    link: cls(styles.link),
                                }}
                            />
                        </Col>
                    ) : null}
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
