import React from 'react';

import { Row, Col, cls } from '@solublestudio/soluto-design-system';
import Heading from '@ds/components/shared/Heading';
import Text from '@ds/components/shared/Text';
import Link from '@ds/components/shared/Link';

import type { LinkProps } from '@ds/components/shared/Link';

import styles from './styles.module.scss';

export interface SectionsTitleProps {
    title?: string;
    subtitle?: string;
    component?: React.ElementType;
    className?: string;
    link?: LinkProps;
    subtitleCols?: number;
}

const SectionsTitle: React.FC<SectionsTitleProps> = ({
    title,
    subtitle,
    component,
    className,
    link,
    subtitleCols = 12,
}) => {
    return (
        <Row className={cls(subtitle && 'mb-lg', className)}>
            {title && (
                <Col data-sal="slide-up" data-sal-delay="300" col={{ lg: 8 }}>
                    <Heading
                        component={component ? component : 'h4'}
                        font="h300"
                        className={subtitle && 'mb-xxxl'}>
                        {title}
                    </Heading>
                </Col>
            )}
            {(link || subtitle) && (
                <Col
                    col={{ xs: subtitleCols }}
                    data-sal="slide-up"
                    data-sal-delay="400"
                    className={cls(
                        'd-flex align-items-start align-items-lg-center justify-content-between',
                    )}>
                    {subtitle && (
                        <Text component="div" font="b300">
                            {subtitle}
                        </Text>
                    )}
                    {link && (
                        <Link
                            {...link}
                            font="b300"
                            className={cls(styles.link, 'ml-xs')}>
                            {link.label}
                        </Link>
                    )}
                </Col>
            )}
        </Row>
    );
};

export default SectionsTitle;
