import React, { useState } from 'react';

import {
    Section,
    Container,
    Row,
    Col,
    cls,
    Slider,
    SliderProps,
    SliderControlProps,
    Icon,
    Image,
} from '@solublestudio/soluto-design-system';

import type { ImageProps } from '@solublestudio/soluto-design-system';

import Heading from '@ds/components/shared/Heading';
import Text from '@ds/components/shared/Text';
import SectionsTitle from '@ds/components/shared/SectionsTitle';
import Button, { CRVButtonProps } from '@ds/components/shared/Button';

import Picture from 'Picture';

import styles from './styles.module.scss';

interface Item {
    pretitle: string;
    title: string;
    text: string;
    image: ImageProps['src'];
    link: CRVButtonProps;
}

export interface CarouselPhotoContentSectionProps extends SliderProps {
    items: Item[];
    title?: string;
    isLight?: boolean;
}

const CarouselPhotoContentSection: React.FC<
    CarouselPhotoContentSectionProps
> = ({ items = [], title, isLight = false }) => {
    const settings = {
        controls: false,
        nav: false,
        loop: true,
    };

    const [slideActive, setSlideActive] = useState(1);

    const onSlideChange = (info: any) => {
        setSlideActive(info.displayIndex);
    };

    return (
        <Section>
            {title && (
                <Container>
                    <SectionsTitle subtitle={title} />
                </Container>
            )}
            <Section
                component="div"
                className={cls(
                    'position-relative mb-mega d-flex flex-column-reverse flex-lg-column',
                    isLight
                        ? 'bg-base-1000 text-base-000'
                        : 'bg-base-100 text-base-1000',
                )}
                data-sal="slide-up"
                data-sal-delay="300"
                data-sal-duration="400"
                data-theme={isLight ? 'light' : 'dark'}>
                <Container>
                    <Row noGutters>
                        <Col
                            col={{ lg: 6 }}
                            offset={{ lg: 6 }}
                            className={cls(
                                styles.content,
                                'pt-xl',
                                'd-flex',
                                'flex-column',
                                'justify-content-between',
                            )}>
                            <Slider
                                onIndexChanged={onSlideChange}
                                settings={settings}
                                Controls={(props: SliderControlProps) => {
                                    return (
                                        <div
                                            className={cls(
                                                styles.controls,
                                                'd-flex',
                                                'align-items-center',
                                                'my-lg',
                                            )}>
                                            <button
                                                onClick={props.onClickPrev}
                                                className={cls(styles.button)}>
                                                <Icon
                                                    name="chevronLeft"
                                                    size={32}
                                                    classes={{
                                                        icon: cls(
                                                            styles.icon,
                                                            isLight &&
                                                                styles.light,
                                                            'mr-tiny',
                                                        ),
                                                    }}
                                                />
                                            </button>
                                            <Text
                                                component="div"
                                                className={cls(
                                                    styles.controlsNum,
                                                )}
                                                font="b400">{`${slideActive}<span>/</span>${items.length}`}</Text>
                                            <button
                                                onClick={props.onClickNext}
                                                className={cls(styles.button)}>
                                                <Icon
                                                    name="chevronRight"
                                                    size={32}
                                                    classes={{
                                                        icon: cls(
                                                            styles.icon,
                                                            isLight &&
                                                                styles.light,
                                                            'ml-tiny',
                                                        ),
                                                    }}
                                                />
                                            </button>
                                        </div>
                                    );
                                }}>
                                {items.map((item, i) => {
                                    return (
                                        <div key={`carousel-photo-item-${i}`}>
                                            <Text
                                                font="b200"
                                                component="p"
                                                className={cls(
                                                    styles.pretitle,
                                                    'mb-xs',
                                                )}>
                                                {item.pretitle}
                                            </Text>
                                            <Heading
                                                font="h300"
                                                component="h4"
                                                className="mb-md">
                                                {item.title}
                                            </Heading>
                                            <div className={cls(styles.text)}>
                                                <Text
                                                    font="h100"
                                                    component="div">
                                                    {item.text}
                                                </Text>
                                                {item.link && (
                                                    <Button
                                                        {...item.link}
                                                        variant="primary-small"
                                                        className="mt-xl"></Button>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </Slider>
                        </Col>
                    </Row>
                </Container>
                <div className={cls(styles.imgContainer)}>
                    {items.map((item, i) => {
                        if (!item?.image) return null;

                        return (
                            <div
                                className={cls(
                                    styles.imgBg,
                                    i + 1 === slideActive ? styles.visible : '',
                                )}>
                                <Picture
                                    image={item?.image}
                                    alt={item?.title}
                                    objectFit="cover"
                                    layout="background"
                                />
                            </div>
                        );
                    })}
                </div>
            </Section>
        </Section>
    );
};

export default CarouselPhotoContentSection;
