import { PictureProps } from 'Picture';

export function getColorByImage(image: PictureProps['image']) {
    return (
        image?.file?.childImageSharp?.gatsbyImageData?.backgroundColor ??
        image?.file?.backgroundColor ??
        image?.backgroundColor ??
        null
    );
}
