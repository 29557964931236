import React from 'react';

import {
    Section,
    Container,
    Row,
    Col,
    cls,
} from '@solublestudio/soluto-design-system';

import Link from '@ds/components/shared/Link';
import Heading from '@ds/components/shared/Heading';

import styles from './styles.module.scss';

export interface CategoriesSectionProps {
    categories: any[];
    ctaLabelSingular?: string | null;
    ctaLabelPlural?: string | null;
}

const CategoriesSection = ({
    categories = [],
    ctaLabelSingular,
    ctaLabelPlural,
}: CategoriesSectionProps) => {
    return (
        <Section className="pb-mega">
            <Container>
                {categories?.length
                    ? categories.map(({ name, slug, total }, i) => (
                          <div
                              key={`category-${i}`}
                              className={cls(
                                  'pt-xl',
                                  'pb-xl',
                                  styles.row,
                                  'd-lg-flex flex-lg-row justify-content-lg-between align-items-lg-baseline',
                              )}
                              data-sal="slide-down"
                              data-sal-delay={200 * i}>
                              <Heading component="h4" font="h400">
                                  {name}
                              </Heading>
                              {ctaLabelSingular && ctaLabelPlural && total ? (
                                  <Link
                                      href={slug}
                                      className={cls(
                                          'd-inline-block',
                                          'mt-modulor',
                                      )}
                                      font="b300">
                                      {total === 1
                                          ? ctaLabelSingular
                                          : ctaLabelPlural.replace(
                                                '{number}',
                                                total,
                                            )}
                                  </Link>
                              ) : null}
                          </div>
                      ))
                    : null}
            </Container>
        </Section>
    );
};

export default CategoriesSection;
