import React, {
    forwardRef,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';

import { Video, cls } from '@solublestudio/soluto-design-system';
import Button from '@ds/components/shared/Button';

import styles from './styles.module.scss';

export interface CardVideoProps {
    classes?: {
        wrapper?: string;
        videoWrapper?: string;
    };
    video?: any;
    showPlayOnEnd?: boolean;
    showControls?: boolean;
    isFluid?: boolean;
    onEnd?: (e: Event) => void;
    onPlay?: (e: Event) => void;
    onCanPlay?: (e: Event) => void;
}

const CardVideo = forwardRef<HTMLDivElement, CardVideoProps>(function (
    {
        classes,
        video,
        showPlayOnEnd = true,
        showControls = false,
        isFluid = true,
        onEnd,
        onPlay,
        onCanPlay,
    },
    ref,
) {
    const [showVideo, setShowVideo] = useState(false);
    const [videoStarted, setVideoStarted] = useState(false);

    const wrapperRef = useRef<HTMLDivElement>(null);
    const videoRef = useRef<HTMLVideoElement | null>(null);

    useEffect(() => {
        if (wrapperRef?.current) {
            const videoElement = wrapperRef.current.querySelector('video');
            if (videoElement) {
                videoRef.current = videoElement;
            }
        }
    }, []);

    useEffect(() => {
        if (!videoRef?.current) {
            return;
        }

        const thisOnCanPlay = (e: Event) => {
            setShowVideo(true);
            if (typeof onCanPlay === 'function') onCanPlay(e);
        };

        const thisOnPlay = (e: Event) => {
            setVideoStarted(true);
            videoRef?.current?.setAttribute('controls', 'controls');
            if (typeof onPlay === 'function') onPlay(e);
        };

        const thisOnEnd = (e: Event) => {
            showPlayOnEnd && setVideoStarted(false);
            videoRef?.current?.removeAttribute('controls');
            if (typeof onEnd === 'function') onEnd(e);
        };

        videoRef?.current?.addEventListener('canplay', thisOnCanPlay);
        videoRef?.current?.addEventListener('play', thisOnPlay);
        videoRef?.current?.addEventListener('ended', thisOnEnd);

        return () => {
            videoRef?.current?.removeEventListener('canplay', thisOnCanPlay);
            videoRef?.current?.removeEventListener('play', thisOnPlay);
            videoRef?.current?.removeEventListener('ended', thisOnEnd);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoRef?.current]);

    const playVideo = useCallback(() => {
        if (!videoRef?.current) {
            return;
        }

        videoRef?.current.play();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoRef?.current]);

    return (
        <div className={cls(styles.wrapper, classes?.wrapper)} ref={wrapperRef}>
            <Video
                ref={ref}
                classes={{
                    wrapper: cls(
                        styles.video,
                        showVideo && styles.videoShown,
                        classes?.videoWrapper,
                    ),
                }}
                sources={[
                    {
                        src: video.url,
                        type: video.extension,
                    },
                ]}
                controls={showControls}
                fluid={isFluid}
            />
            <Button
                variant="iconButton"
                iconName="play"
                component="button"
                onClick={playVideo}
                className={cls(
                    styles.playBtn,
                    videoStarted && styles.playBtnHide,
                )}
            />
        </div>
    );
});

export default CardVideo;
