import React from 'react';
import { Row, Col, cls } from '@solublestudio/soluto-design-system';

import Text from '@ds/components/shared/Text';

import styles from './styles.module.scss';

export interface PostContentProps {
    className?: string;
    textLeft: string;
    textRight?: string;
}

export default function PostContent({
    className,
    textLeft,
    textRight,
}: PostContentProps) {
    return (
        <Row className={className}>
            <Col
                col={{ lg: 6, xs: 12 }}
                className={cls(styles.item)}
                data-sal="fade">
                <Text font="b300" component={'div'} addBreaklines={false}>
                    {textLeft}
                </Text>
            </Col>
            {textRight && (
                <Col
                    col={{ lg: 6, xs: 12 }}
                    className={cls(styles.item)}
                    data-sal="fade"
                    data-sal-delay="200">
                    <Text font="b300" component={'div'} addBreaklines={false}>
                        {textRight}
                    </Text>
                </Col>
            )}
        </Row>
    );
}
