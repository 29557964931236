import React from 'react';
import { Container, Section, cls } from '@solublestudio/soluto-design-system';

import TextContentPages from '@ds/components/shared/TextContentPages';
import type { TextContentPagesProps } from '@ds/components/shared/TextContentPages';

export interface TextContentPagesSectionProps
    extends Pick<TextContentPagesProps, 'title' | 'text'> {}

const TextContentPagesSection: React.FC<TextContentPagesSectionProps> = ({
    title,
    text,
}) => {
    return (
        <Section className={cls('mb-xxxl')}>
            <Container>
                <TextContentPages title={title} text={text} />
            </Container>
        </Section>
    );
};

export default TextContentPagesSection;
