import React from 'react';

import Anchor from '@ds/components/shared/Anchor';
import Heading from '@ds/components/shared/Heading';
import Text from '@ds/components/shared/Text';
import {
    cls,
    Container,
    HubspotForm,
    Section,
} from '@solublestudio/soluto-design-system';

import MediaBackgroundComponent from '../../shared/MediaBackgroundComponent';
import useIsMobile from '@ds/hooks/useIsMobile';

import styles from './styles.module.scss';

export interface PreFooterFormProps {
    title: string;
    form: {
        id: string;
        portalId: string;
        region: string;
    };
    formText?: string;
    background: any;
    anchor?: string;
}

const PreFooterForm = ({
    title,
    formText,
    background,
    form,
    anchor,
}: PreFooterFormProps) => {
    const isMobile = useIsMobile();

    return (
        <Section className={cls(styles.preFooterForm, 'bg-primary', 'mb-mega')}>
            {!!anchor && <Anchor anchor={anchor} />}
            {!isMobile && background && (
                <MediaBackgroundComponent
                    background={background}
                    withDelay={false}
                    classes={{
                        wrapper: cls('d-none d-xl-block'),
                    }}
                />
            )}
            <Container className={cls(styles.container, 'text-base-000')}>
                <div className={cls(styles.content, 'p-xl')}>
                    <Heading
                        component="h2"
                        font="h300"
                        className={cls('mb-xxl')}
                        data-sal="slide-right"
                        data-sal-delay="300">
                        {title}
                    </Heading>
                    <div className={styles.wrapperForm}>
                        <HubspotForm
                            region={form?.region}
                            formId={form?.id}
                            portalId={form?.portalId}
                            className={styles.form}
                            scriptProps={{
                                defer: true,
                            }}
                        />
                        {formText && (
                            <Text
                                data-sal="slide-right"
                                data-sal-delay="300"
                                component="div"
                                font="b100"
                                className={cls('mt-lg', styles.formText)}>
                                {formText}
                            </Text>
                        )}
                    </div>
                </div>
            </Container>
        </Section>
    );
};

export default PreFooterForm;
