import React, { useRef, useState } from 'react';

import {
    cls,
    Section,
    Container,
    Text,
    Icon,
} from '@solublestudio/soluto-design-system';

import SectionsTitle, {
    SectionsTitleProps,
} from '@ds/components/shared/SectionsTitle';

import Picture, { PictureProps } from 'Picture';

import styles from './styles.module.scss';

interface Project {
    image: PictureProps['image'];
    title: string;
    projectStatus: string;
    kind: string;
    power: string;
    location: string;
    description?: string;
    __typename: string;
}

export interface TableWebSectionProps extends SectionsTitleProps {
    items: Project[];
    translates?: {
        viewMore?: string;
        viewMinus?: string;
    };
}

function TableWebSection({
    title,
    subtitle,
    items = [],
    translates,
}: TableWebSectionProps) {
    const ref = useRef(null as any);
    const [showMore, setShowMore] = useState(false);

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setShowMore(!showMore);
        const rows = ref.current.querySelectorAll('.tr-partners');
        rows.forEach((row: any) => {
            row.classList.toggle(styles.hidden);
        });
    };

    return (
        <Section className="pb-huge">
            <Container>
                {title || subtitle ? (
                    <SectionsTitle
                        title={title}
                        subtitle={subtitle}
                        className={subtitle ? '' : 'mb-xxxl'}
                    />
                ) : null}
                <div className={styles.tableWebSection}>
                    <table ref={ref}>
                        <tbody>
                            {items.map((item, index) =>
                                item.__typename === 'DatoCmsPartner' ? (
                                    <tr
                                        key={`table-item-partners-${item?.title}-${index}`}
                                        className={`${styles.rowPartner} ${
                                            index > 7 &&
                                            `${styles.hidden} tr-partners`
                                        } `}
                                        data-sal="slide-up"
                                        data-sal-delay="400">
                                        <td>
                                            <Picture image={item?.image} />
                                        </td>
                                        <td>
                                            <Text component="p">
                                                {item?.title}
                                            </Text>
                                        </td>

                                        <td>
                                            <Text
                                                component="p"
                                                className={cls('text-left')}>
                                                {item?.description}
                                            </Text>
                                        </td>

                                        <td>
                                            <Text component="p">
                                                {item?.kind}
                                            </Text>
                                        </td>
                                    </tr>
                                ) : (
                                    <tr
                                        key={`table-item-${item?.title}-${index}`}
                                        data-sal="slide-up"
                                        data-sal-delay="400">
                                        <td>
                                            <Picture
                                                image={item?.image}
                                                width={54}
                                                height={67}
                                            />
                                        </td>
                                        <td>
                                            <Text component="p">
                                                {item?.title}
                                            </Text>
                                        </td>
                                        <td>
                                            <Text component="p">
                                                {item?.projectStatus}
                                            </Text>
                                        </td>
                                        <td>
                                            <Text component="p">
                                                {item?.power}
                                            </Text>
                                        </td>
                                        <td>
                                            <Text component="p">
                                                {item?.kind}
                                            </Text>
                                        </td>
                                        <td>
                                            <Text component="p">
                                                {item?.location}
                                            </Text>
                                        </td>
                                    </tr>
                                ),
                            )}
                        </tbody>
                    </table>

                    {items.length > 8 &&
                        items[0].__typename === 'DatoCmsPartner' && (
                            <div
                                className={cls(
                                    'd-flex justify-content-center mt-md cursor-pointer',
                                )}>
                                <span
                                    className={styles.pointer}
                                    onClick={(
                                        e: React.MouseEvent<HTMLAnchorElement>,
                                    ) => handleClick(e)}>
                                    {showMore
                                        ? translates?.viewMinus
                                        : translates?.viewMore}
                                    <Icon
                                        name={
                                            showMore
                                                ? 'chevronUp'
                                                : 'chevronDown'
                                        }
                                        size={24}
                                    />
                                </span>
                            </div>
                        )}
                </div>
            </Container>
        </Section>
    );
}

export default TableWebSection;
