import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';

import Heading from '@ds/components/shared/Heading';

import CardMediaBase, { CardMediaBaseProps } from '..';

import styles from './styles.module.scss';

export interface CardMediaTextProps extends CardMediaBaseProps {
    title: string;
}

const CardMediaText = ({ title, ...props }: CardMediaTextProps) => {
    return (
        <CardMediaBase {...props}>
            <Heading
                data-theme="light"
                font={props.smallSize ? 'h200' : 'h300'}
                className={cls(
                    styles.title,
                    props.smallSize ? ' py-md pl-md' : 'py-lg pl-lg',
                    'pr-xl',
                )}
                component="h4">
                {title}
            </Heading>
        </CardMediaBase>
    );
};

export default CardMediaText;
