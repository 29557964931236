import React from 'react';

import { cls, Icon } from '@solublestudio/soluto-design-system';

import { useModal } from '@ds/hooks/useModal';
import CardBgImageBase, { CardBgImageBaseProps } from '../BgImage';

import styles from './styles.module.scss';

export interface CardVideoPlaylistProps extends CardBgImageBaseProps {
    videoId: string;
    date: string;
    duration: string;
}

const CardVideoPlaylist = ({
    videoId,
    date,
    duration,
    ...props
}: CardVideoPlaylistProps) => {
    const videoInfo = `<p class="${cls(
        'text-capitalize',
    )}">${date}</p><p>${duration}</p>`;

    const { openModal } = useModal();
    return (
        <CardBgImageBase
            {...props}
            dataTheme="dark"
            modalId={videoId}
            classes={{
                wrapper: cls(styles.card),
                title: cls(styles.title),
                category: cls(styles.category),
            }}
            titleFont="b300"
            category={videoInfo}
            categoryFont="h300"
            contentTitle={
                <button
                    className={cls(styles.button)}
                    onClick={() => openModal(videoId)}>
                    <Icon
                        name="play"
                        size={24}
                        classes={{
                            icon: cls(styles.buttonIcon),
                        }}
                    />
                </button>
            }>
            <div className={styles.topShadow} />
        </CardBgImageBase>
    );
};

export default CardVideoPlaylist;
