import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';
import Picture, { PictureProps } from 'Picture';
import Text from '@ds/components/shared/Text';

export interface CardImageLegendProps {
    image: PictureProps['image'];
    legend?: string;
    className?: string;
}

const CardMediaBase: React.FC<CardImageLegendProps> = ({
    legend,
    image,
    className,
}) => {
    return (
        <div>
            <div className={cls('position-relative', 'bg-base-400', className)}>
                <Picture
                    image={image}
                    alt={image?.alt ?? undefined}
                    layout="full"
                    objectFit="cover"
                    aspectRatio={0.5828}
                />
            </div>
            {Boolean(legend) && (
                <Text font="b100" className="mt-lg" component="p">
                    {legend}
                </Text>
            )}
        </div>
    );
};

export default CardMediaBase;
