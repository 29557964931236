import React from 'react';

import { Row, Col, cls } from '@solublestudio/soluto-design-system';

import Heading from '@ds/components/shared/Heading';
import Text from '@ds/components/shared/Text';

export interface TextContentPagesProps {
    title?: string;
    text?: string;
}

const TextContentPages: React.FC<TextContentPagesProps> = ({ title, text }) => {
    return (
        <>
            {title && (
                <Row>
                    <Col col={{ lg: 5 }}>
                        <Heading
                            component="h2"
                            font="h300"
                            className={cls('mb-xxxl')}>
                            {title}
                        </Heading>
                    </Col>
                </Row>
            )}
            {text && (
                <Row>
                    <Col col={{ lg: 7 }}>
                        <Text component="div" font="h100">
                            {text}
                        </Text>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default TextContentPages;
