import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';

import Heading from '@ds/components/shared/Heading';
import Text from '@ds/components/shared/Text';
import Button, { CRVButtonProps } from '@ds/components/shared/Button';

import Link from '@ds/components/shared/Link';

import styles from './styles.module.scss';
import Topics, { TopicsProps } from '../Topics';

export interface CardInfoPostProps {
    time: number | string;
    category: string;
    topics: TopicsProps['topics'];
    title: string;
    cta?: CRVButtonProps;
    withCard?: boolean;
    slug: string;
    dataTheme: string;
}

const CardInfoPost: React.FC<CardInfoPostProps> = ({
    time,
    category,
    topics,
    title,
    cta,
    withCard = false,
    slug,
    dataTheme,
}) => {
    return (
        <div
            className={cls(styles.wrapper, withCard && styles.card)}
            data-theme={dataTheme}>
            <div>
                {category ? (
                    <Text component="span" className="mr-md" font={'b300'}>
                        {category}
                    </Text>
                ) : null}
                <Text component="span" font={'b300'}>
                    {`${Math.round(parseFloat(time as string))} min`}
                </Text>
            </div>
            <div className={cls('mb-sm', 'mt-modulor')}>
                <Topics topics={topics} />
            </div>
            <Link href={slug}>
                <Heading component="h1" font={withCard ? 'h400' : 'h500'}>
                    {title}
                </Heading>
            </Link>
            {cta ? (
                <div className="pt-huge">
                    <Button {...cta} label={cta?.label} />
                </div>
            ) : null}
        </div>
    );
};

export default CardInfoPost;
